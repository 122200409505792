import axios from 'axios';

let user = localStorage.getItem('user')
user = JSON.parse(user)
console.log(user,'user')

const getApi = async (isFormData) => {

  const api = axios.create({
  // baseURL: process.env.REACT_API_BASE_URL, //API base URL here
  baseURL: 'https://backend.byte-crew.com/api/v1/', //API base URL here
  timeout: 10000, // Adjust the timeout as needed
  headers: {
    'Authorization': `Bearer ${user?.token}`, // Set the Bearer token here
    'Content-Type': isFormData ? 'multipart/form-data' :'application/json',
    'accept': 'application/json'
  },
  
})
    return api
}


const handleSuccess = (response) => {
     if(response.data?.data){
      return response.data
     }else if(response.data){
       return response
     }else{
      return response
     }
} 

const handleError = (error) => {
  // Customize error handling as per requirements
  console.error('API Error:', error);
  throw error;
};

const convertBooleanToNumber = (data) => {
  // Check if 'data' is an object and contains keys with boolean values
  if (data && typeof data === 'object') {
    const transformedData = { ...data };
    Object.keys(transformedData).forEach(key => {
      if (typeof transformedData[key] === 'boolean') {
        transformedData[key] = transformedData[key] ? 1 : 0;
      }
    });
    return transformedData;
  }
  return data;
};


const apiCall = async (method, url, data = null, params = null,isFormData=false) => {
  let response;
  try {
    switch (method) {
      case 'GET':
         response = await getApi()
         response =  await response.get(url, { params })
        break;
      case 'POST':
        response = await getApi(isFormData)
        response = await response.post(url, convertBooleanToNumber(data))
        break;
      case 'PUT':
        response = await getApi()
        response = await response.put(url, convertBooleanToNumber(data))
        break;
      case 'DELETE':
        response = await getApi()
        response = await response.delete(url)
        break;
      default:
        throw new Error(`Unsupported method: ${method}`);
    }
    return handleSuccess(response);
  } catch (error) {
    return handleError(error);
  }
};
export default apiCall;
