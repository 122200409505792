import React,{useState,useEffect} from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputOtp } from 'primereact/inputotp';
import apiCall from "../../../../../api/Api";
import * as yup from "yup";
import { InputText } from 'primereact/inputtext';
import { FloatLabel } from 'primereact/floatlabel';
import { Password } from 'primereact/password';
import { requestForToken } from '../../../../../Firebase/firebase';

// List of common free email domains
const freeEmailDomains = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'outlook.com',
  'icloud.com',
  // Add more free domains as needed
];



export default function SignIn() {
  const navigate = useNavigate();
  const [showOTP, setShowOTP] = useState(false);
  const [isCompanyLogin, setIsCompanyLogin] = useState(false)
  const location = useLocation()

  useEffect(() => {
   if(location.pathname === '/company_sign_in') setIsCompanyLogin(true)
  }, [location.pathname])
  
  const schema = yup.object({
    email: yup
    .string()
    .email('Invalid email')
    .required('Email is required')
    .test('is-not-free-email', 'Email must be from a specific domain', value => {
      if (!value) return false;
      const domain = value.split('@')[1];
      return !freeEmailDomains.includes(domain);
    }),
    password: yup.string().required("Password field is required."),
  });

  const {register,setValue, handleSubmit: onSubmit,formState: { errors }} = useForm({ resolver: yupResolver(schema) });


  const handleSubmit = async (data) => {
    console.log(data);
    if(!showOTP && data.email && isCompanyLogin){
      setShowOTP(!showOTP)
    }else{
      // const signInData = {
      //   ...data,
      //   role:'company_admin',
      //   status:'pending'
      // }
      const userData = {
          ...data,
          fcm_token: 'cTNacv7zRjOPccbnMZr7vo:APA91bHjhiZLqQwGDhDOBJDT0p8YPaWMo0jNwe7d6zXJ4N0Pe6GvweF60GJ31hwRT9iBUSMV7BP1asXWQAaDsZTZ0uLSL0aiO9Quu8CkgQVeAmlr5XZTXC_Bsk49HYuG_fgamgoSzvDH',
          platform_type: 'web',
        };
      try {
        let response = await apiCall("POST", "login", userData);
        console.log(response,'res')
        response =  response?.data
        let localStorageData = response?.user
         localStorageData = {
          ...localStorageData,
          token : response?.token,
          role: response?.role
        }
        console.log(localStorageData,'localstorage')
        localStorage.setItem('user', JSON.stringify(localStorageData));
        // console.log("User created:", signInData);
       await requestForToken()
        navigate('/customers')
        window.location.reload()
      //   navigate('/ecommerce')
      } catch (error) {
        console.error("Failed to login:", error);
      }
    }
   
  };

  return (
    <div className="flex flex-col items-center">
      <form
        onSubmit={onSubmit(handleSubmit)}
        className="flex h-[calc(100vh-100px)] flex-col justify-center items-center outline-none"
      >
        <p className="place-self-center font-semibold text-base text-[#5473E3]">
          Login to the system
        </p>
        <FloatLabel>
          <label htmlFor="email" className="block m-1">
            Email
          </label>
          <InputText
            {...register("email")}
            type="email"
            name="email"
            // placeholder="Email"
            className={
              errors.email
                ? "block peer rounded-[5px] w-[25rem] mt-5 border-[#C93B32] focus:outline-none focus:border-[#C93B32]  focus:ring-1 focus:ring-[#C93B32]"
                : "block peer rounded-[5px] border-[#AEBBCD] mt-3 w-[25rem] focus:outline-none focus:ring-1"
            }
          />
            </FloatLabel>
          <span className="place-self-start text-[14px] text-[#C93B32] mt-3">
            {errors.email?.message}
          </span>
      

        {showOTP && (
          <div className='mt-5 flex gap-5'>
            <label htmlFor="email_otp" className="block m-2">
              Email OTP
            </label>
            <InputOtp
              // name="email_otp"
              {...register("email_otp")}
              // value={token}
              onChange={(e) => console.log(e.value)}
              integerOnly
              className={
                errors.email_otp
                  ? "block peer rounded-[5px] w-[25rem]  mt-5 border-[#C93B32] focus:outline-none focus:border-[#C93B32]  focus:ring-1 focus:ring-[#C93B32]"
                  : "block peer rounded-[5px] border-[#AEBBCD] w-[25rem] mt-5 focus:outline-none focus:ring-1"
              }
            />
            <span className="place-self-start text-[14px] text-[#C93B32]">
              {errors.email_otp?.message}
            </span>
          </div>
        )}

        {!isCompanyLogin && (
          <div style={{width:'100%', marginTop:"8%"}}>

            <FloatLabel>
                <Password 
                {...register("password")}
                inputId="password" 
                // required
                name='password'
                toggleMask
                inputStyle={{
                  width:'100%'
                }}
                // value={value} 
                onChange={(e) => setValue('password',e.target.value)} 
                className={
                  errors.password
                    ? "block peer rounded-[5px] w-[25rem]  mt-5 border-[#C93B32] focus:outline-none focus:border-[#C93B32]  focus:ring-1 focus:ring-[#C93B32]"
                    : "block peer rounded-[5px] border-[#AEBBCD] focus:outline-none focus:ring-1"
                }
                />
                <label htmlFor="password">Password</label>
            </FloatLabel>

            {/* <input
              {...register("password")}
              type="password"
              placeholder="Password"
              className={
                errors.password
                  ? "block peer rounded-[5px] w-[25rem] mt-5 border-[#C93B32] focus:outline-none focus:border-[#C93B32]  focus:ring-1 focus:ring-[#C93B32]"
                  : "block peer rounded-[5px] border-[#AEBBCD] w-[25rem] mt-5 focus:outline-none focus:ring-1"
              }
            /> */}
            <span className="place-self-start text-[14px] text-[#C93B32]">
              {errors.password?.message}
            </span>
         

        </div>
        )}
        <Link
          to="/forgotpassword"
          className="place-self-end hover:text-[#2347C5] hover:underline mt-3"
        >
          <p className="text-[#5473E3]">Forgot Password?</p>
        </Link>
        <button
          type="submit"
          className={`rounded-full bg-[#3D5FD9] text-[#F5F7FF] w-[25rem] p-3 mt-5 hover:bg-[#2347C5] mb-5`}
        >
          SIGN IN
        </button>

        <Link to="/signup" className="hover:text-[#2347C5] hover:underline">
          <p className="text-[#5473E3] mb-5">Don't have an account? Sign up</p>
        </Link>
        
        <Link
          to= {isCompanyLogin ? "/" : 'company_sign_in'}
          className="hover:text-[#2347C5] hover:underline"
        >
          <p className="text-[#5473E3] mb-5">{ isCompanyLogin ? 'Admin? Sign In' : 'Company Owner? Sign In'}</p>
        </Link>
      </form>
    </div>
  );
}
