// DetailPage.js

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import apiCall from '../../../../api/Api'

const Detail = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    // Fetch data from JSONPlaceholder API based on post ID
    const fetchData = async () => {
      try {
        // const response = await apiCall("get", `https://jsonplaceholder.typicode.com/posts/${id}`);
        const response = await apiCall("get", `https://jsonplaceholder.typicode.com/posts/1`);
        setData(response);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <div className="m-5 p-d-flex p-jc-center">
      {data ? (
        <Card title={`Post Detail - ID: ${id}`}>
          <div>
            <h2>{data.title}</h2>
            <p>{data.body}</p>
            {/* Add more details based on your API response */}
          </div>
          <Button label="Go Back" onClick={() => window.history.back()} className="p-button-secondary" />
        </Card>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default Detail;
