import React from 'react';

const ContactDetail = ({ icon, label, value }) => (
  <div className="flex items-center space-x-2">
    <i className={`${icon} text-xl text-blue-600`}></i>
    <div>
      <h3 className="text-gray-500">{label}</h3>
      <p className="font-semibold">{value}</p>
    </div>
  </div>
);

export default ContactDetail;
