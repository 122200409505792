import React from "react";
import Form from "../../../../components/Form";
import * as yup from "yup";

const Add = () => {

  const schema = yup.object({
    withdrawaable_type: yup.string().required("Withdrawal Type is required."),
    withdrawaable_id: yup.string().required("Withdrawal ID is required."),
    status: yup.string().required("Status is required."),
  });


  const fields = [
    {
      type: "text",
      name: "withdrawaable_id",
      placeholder: "Select Withdrawal ID",
      column: 4,
    },
    {
      type: "text",
      name: "withdrawaable_type",
      placeholder: "Select Withdrawal Type",
      column: 4,
    },
    {
      type: "toggle",
      name: "status",
      placeholder: "Select Status",
      column: 4,
    },
  
  ];

  return (
    <Form title="Withdrawal Request" schema={schema} fields={fields} />
  );
};

export default Add;
