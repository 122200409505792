import React, { useState ,useEffect} from "react";
// import Form from "./Form";
import * as yup from "yup";
// import Form from "../../../../components/Form";
import GeekAdd from '../../../Geeks/src/components/GeekAdd'
import apiCall from "../../../../api/Api";

const Add = () => {

  const [skills, setSkillsList] = useState(null);
  useEffect(() => {
    const getSkills = async () => {
      try {
        const response = await apiCall("GET", "all-skills");
        let skl = response?.data?.filter(sk => (sk?.parent === null) && {
            name: sk.name,
            code : sk.id
      })
      console.log(skl,'skl')
        setSkillsList(skl)
      } catch (error) {
        console.error("Failed to Add:", error);
      }
    }
    getSkills()
  }, []);

  const schema = yup.object({
    name: yup.string().required("Company name is required."),
    adrr_1: yup.string().required("Address is required."),
    adrr_2: yup.string().required("Street Address is required."),
    country: yup.string().required("Country is required."),
    state: yup.string().required("State is required."),
    city: yup.string().required("City field is required."), 
    zip_code: yup.string().required("Postal Code field is required."), 
    lat: yup.string().required("Latitude field is required."), 
    long: yup.string().required("Longitude field is required."), 
    // license: yup
    //   .mixed()
    //   .test("isImage", "Logo must be an image file", (value) => {
    //     if (!value) return true; // Allow empty values
    //     return (
    //       value && ["image/jpeg", "image/png", "image/gif"].includes(value.type)
    //     );
    //   }),
    //   insurance: yup.mixed().test("isImage", "Logo must be an image file", (value) => {
    //   if (!value) return true; // Allow empty values
    //   return (
    //     value && ["image/jpeg", "image/png", "image/gif"].includes(value.type)
    //   );
    // }),
  });

  // const cities = [
  //   { name: "Apple", code: 1 },
  //   { name: "Tesla", code: 2 },
  //   { name: "Microsoft", code: 3 },
  //   { name: "Ford", code: 4 },
  //   { name: "Buggati", code: 5 },
  // ];

  const fields = [
    {
      type: "text",
      name: "name",
      placeholder: "Name",
      column: 4,
    },
    {
      type: "email",
      name: "email",
      placeholder: "Email",
      column: 4,
    },
    {
      type: "password",
      name: "password",
      placeholder: "Password",
      column: 4,
    },
    {
      type: "text",
      name: "mobile",
      placeholder: "Mobile",
      column: 4,
    },
    {
      type: "text",
      name: "adrr_1",
      placeholder: "Address",
      column: 4,
    },
    {
      type: "text",
      name: "adrr_2",
      placeholder: "Address",
      column: 4,
    },
    {
      type: "text",
      name: "building_name",
      placeholder: "Building Name",
      column: 4,
    },
    {
      type: "number",
      name: "building_no",
      placeholder: "Building No",
      column: 4,
    },
    {
      type: "number",
      name: "apartment_no",
      placeholder: "Apartment No",
      column: 4,
    },
    {
      type: "number",
      name: "radius",
      placeholder: "Radius",
      column: 4,
    },
    {
      type: "number",
      name: "floor_no",
      placeholder: "Floor No",
      column: 4,
    },
   
    {
      type: "text",
      name: "state",
      placeholder: "State",
      column: 4,
    },
    {
      type: "text",
      name: "country",
      placeholder: "Country",
      column: 4,
    },
    {
      type: "text",
      name: "city",
      placeholder: "City",
      column: 4,
    },
    { type: "text", name: "domain", placeholder: "Domain Name", column: 4 },

    {
      type: "number",
      name: "zip_code",
      placeholder: "Zip Code",
      column: 4,
    },
    {
      type: "location",
      name: "lat",
      placeholder: "Location",
      column: 4,
    },
    {
      type: "toggle",
      name: "is_default",
      placeholder: "Default",
      column: 4,
    },
   
    // {
    //   type: "text",
    //   name: "long",
    //   placeholder: "Longitude",
    //   column: 4,
    // },
    
    { type: "heading", name: "heading", placeholder: "Media Images", column: 4 },
    {
      type: "image",
      name: "license",
      placeholder: "License",
      column: 6,
    },
    {
      type: "image",
      name: "insurance",
      placeholder: "Insurance",
      column: 6,
      // multiple: true,
    },
    { 
      type: "heading",
      name: "heading", 
      placeholder: "Company Skills", 
      column: 4
    },

    { 
      type: "dynamicFields", 
      name: "skills", 
      placeholder: "Skills", 
      column: 4,
      options:skills
    }
  ];

  return (
    <GeekAdd 
      title="Company" 
      schema={schema} 
      fields={fields} 
      apiRoute='companies'
      dynamicFieldsArray={[{ skill_id: '', self_score: 1,one_off_cost_remote:0,one_off_cost_onsite:0,per_hour_cost_onsite:0,per_hour_cost_remote:0,other_details:'',certificate:null }]}  
    />
  );
};

export default Add;
