import React, { useState, useEffect } from "react";
import Company from "../../../../components/Datatable";
// import { ProductService } from "../../../../utils/data";
import '../../../main.css';
import '../../../flags.css';
import Loader from '../../../../components/Loader'
import apiCall from "../../../../api/Api";
const List = () => {
  const [products, setProducts] = useState(null); 
  const [loader, setloader] = useState(false); 
  const [page, setPage] = useState(1); 
  const [perPage, setPerPage] = useState(10); 
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {

    getCompanies()
    
  }, [page, perPage]);
  const getCompanies = async () => {
    try {
      setloader(true)
      const response = await apiCall("GET", `companies?page=${page}&perPage=${perPage}`);
      setProducts(response?.data)
      setTotalRecords(response.meta?.total); // Assuming your API returns total record count
      setloader(false)
    } catch (error) {
      console.error("Failed to Add:", error);
    }
  }
  // columns here from the API
  const column = [
    { 
      name:'id',
      title:'ID',
      sortable:true


    },
    { 
      name:'email',
      title:'Email',
      sortable:true
    },
    { 
      name:'name',
      title:'Name',
      sortable:true

    },
    { 
      name:'company_skills',
      title:'Company Skills',
      type:'formatter',
      keys:['skill_name'],
      sortable:true

    },
    // { 
    //   name:'country',
    //   title:'Country'
    // },
    // { 
    //   name:'state',
    //   title:'State'
    // },
    // { 
    //   name:'city',
    //   title:'City'
    // },
    { 
      name:'insurance',
      title:'Insurance',
      type:'image',
      // sortable:true

    },
    { 
      name:'license',
      title:'License',
      type:'image',
      // sortable:true

    },
    // { 
    //   name:'status',
    //   title:'Status',
    //   type:'status',
      
    // },

    { 
      name:'commission',
      title:'Commission',
      type:'status',
      kind:'easy-edit',
      postUrl:'/add-company-commission',
      sortable:true
    },

    { 
      name:'status',
      title:'Status',
      type:'status',
      kind:'easy-edit',
      postUrl:'/update-company-status',
      dropdownOptions:[
        { label: "Pending", value: "pending" },
        { label: "Active", value: "active" },
        { label: "InActive", value: "inactive" },
      ],
      sortable:true
    },
    
  ]; 

  // Handle page change
  const handlePageChange = (newPage, newRowsPerPage) => {
    setPage(newPage);
    setPerPage(newRowsPerPage);
  };


      // Loader here
      if (loader === true) return <Loader/>;
  return (
    <div>
      <Company 
      title='Companies' 
      data={products} 
      column={column} 
      addRoute='/company/add'
      detailRoute={{route: 'companies',apiUrl:'companies'}} 
      showDetailsRoute={true}
      fetchDataAgain={getCompanies}
      totalRecords={totalRecords}
      perPage={perPage}
      page={page}
      onPageChange={handlePageChange}
      loading={loader}
       />
    </div>
  );
};

export default List;
