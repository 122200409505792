import React, { useState, useEffect } from "react";
import Company from "../../../../components/Datatable";
import { ProductService } from "../../../../utils/data";
import '../../../main.css';
import '../../../flags.css';

const List = () => {
  const [products, setProducts] = useState(null);
  useEffect(() => {
    ProductService.getProducts().then((data) => setProducts(data));
  }, []);
  
  // columns here from the API
  const column = [
    { 
      name:'code',
      title:'ID'
    },
    { 
      name:'addr_1',
      title:'Address'
    },
    { 
      name:'addr_2',
      title:'Address 2'
    },
    { 
      name:'country',
      title:'Country'
    },
    { 
      name:'state',
      title:'State'
    },
    { 
      name:'city',
      title:'City'
    },
    { 
      name:'zip_code',
      title:'Zip'
    },
    
  ]; 

      // Loader here
  return (
    <div>
     { products && <Company title="Addresses" data={products} column={column} addRoute='/address/add' detailRoute='address'/>}
    </div>
  );
};

export default List;
