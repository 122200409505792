import React from 'react'; 
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom/dist';

const CustomCard = ({ title, subTitle, img,name }) => {
    const navigate = useNavigate()
    const header = (value) => (
        <>
            <img alt="Card" src={img} className="w-full" />
            <Button
                icon="pi pi-cog"
                size="large"
                className="font p-button-secondary p-button-text bg-yellow-400 text-white hover:bg-yellow-600"
                style={{ width: '100%', top: '-25px', transition: 'background-color 0.3s ease' }}
                onClick={()=> navigate(`/settings/${value}`)}
            />
        </>
    );
    

    return (
        <div className="card flex justify-content-center" style={{ alignItems: 'center' }}>
            <Card title={title} header={() => header(name)} className="md:w-25rem">
                <p className="text-center">
                    {subTitle}
                </p>
            </Card>
        </div>
    );
}

export default CustomCard;
