import React, {  } from "react";
import Form from "../../../../components/Form";
import * as yup from "yup";

const Add = () => {

  const schema = yup.object({
    name: yup.string().required("Name is required."),
    email: yup.string().required("Email is required."),
    password: yup.string().required("Password is required."),
    verify_at: yup.string().required("Verify At is required."),
  });

  const fields = [
    {
      type: "text",
      name: "name",
      placeholder: "Name",
      column: 4,
    }, 
    {
      type: "text",
      name: "email",
      placeholder: "Email",
      column: 4,
    },
    {
      type: "text",
      name: "password",
      placeholder: "Password",
      column: 4,
    },
    {
      type: "text",
      name: "verify_at",
      placeholder: "Verify At",
      column: 4,
    },
    
  ];

  return (
    <Form title="User" schema={schema} fields={fields} />
  );
};

export default Add;
