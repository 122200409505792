import React, { useState, useEffect } from "react";
import Company from "../../../../components/Datatable";
// import { ProductService } from "../../../../utils/data";
import '../../../main.css';
import '../../../flags.css';
import { Button } from 'primereact/button';
import apiCall from "../../../../api/Api";
import Loader from "../../../../components/Loader";

const List = () => {
  const [products, setProducts] = useState(null); 
  useEffect(() => {
    const getNotifications = async () => {
      try {
        const response = await apiCall("GET", "notifications");
        console.log(response, 'Notifications response');
        setProducts(response)
      } catch (error) {
        console.error("Failed :", error);
      }
    }
    getNotifications()
    
  }, []);
//   const data =  [
//     {
//         id: '1000',
//         code: 'f230fh0g3',
//         description: 'Message Descriptions Here.....',
//         name: 'Bamboo Watch',
//         price: 65,
//         status:0 
//        },
//     {
//         id: '1001',
//         code: 'nvklal433',
//         name: 'Black Watch',
//         job_id: '1450',
//         geek_id: '57',
//         description: 'Message Descriptions Here.....',
//         price: 72,
//         status:0 
//        },
//     {
//         id: '1002',
//         code: 'zz21cz3c1',
//         name: 'Blue Band',
//         job_id: '1450',
//         geek_id: '57',
//         description: 'Message Descriptions Here.....',
//         price: 79,
//         status:0 
//        },
//     {
//         id: '1003',
//         code: '244wgerg2',
//         name: 'Blue T-Shirt',
//         job_id: '1450',
//         geek_id: '57',
//         description: 'Message Descriptions Here.....',
//         price: 29,
//         status:0 

//     },
//     {
//         id: '1004',
//         code: 'h456wer53',
//         name: 'Bracelet',
//         job_id: '1450',
//         geek_id: '57',
//         description: 'Message Descriptions Here.....',
//         price: 15,
//          status:0 

//     },
//     {
//         id: '1005',
//         code: 'av2231fwg',
//         name: 'Brown Purse',
//         job_id: '1450',
//         geek_id: '57',
//         description: 'Message Descriptions Here.....',
//         price: 120,
//         status:0 
//       },
//     {
//         id: '1006',
//         code: 'bib36pfvm',
//         name: 'Chakra Bracelet',
//         job_id: '1450',
//         geek_id: '57',
//         description: 'Message Descriptions Here.....',
//         price: 32,
//         status:0 
//        },
//     {
//         id: '1007',
//         code: 'mbvjkgip5',
//         name: 'Galaxy Earrings',
//         job_id: '1450',
//         geek_id: '57',
//         description: 'Message Descriptions Here.....',
//         price: 34,
//         status:0 
//        },
//     {
//         id: '1008',
//         code: 'vbb124btr',
//         name: 'Game Controller',
//         job_id: '1450',
//         geek_id: '57',
//         description: 'Message Descriptions Here.....',
//         price: 99,
//         status:0 
//        },
//     {
//         id: '1009',
//         code: 'cm230f032',
//         name: 'Gaming Set',
//         job_id: '1450',
//         geek_id: '57',
//         description: 'Message Descriptions Here.....',
//         price: 299,
//         status:0 
//        },
   
// ];

  
  // columns here from the API
  const column = [
    { 
      name:'id',
      title:'ID',
      sortable:true
    },
    { 
      name:'message',
      title:'Message',
      sortable:true
    },
    { 
      name:'subject',
      title:'Subject',
      sortable:true
    },
    { 
      name:'seen',
      title:'Seen',
      sortable:true
    },
    {
      name:'created_at',
      title:'Date Created',
      sortable:true
    }
    // { 
    //   name:'status',
    //   title:'Status',
    //   type:'status'
    // },
   
  ]; 
  const handleDispute = (rowData)  => {
    console.log('clicked',rowData)
  }


  const resolveDispute = (rowData) =>  <Button icon="pi pi-sign-in" rounded outlined className="m-1" onClick={() => handleDispute(rowData)} />

      // Loader here
  if (products === null) return <Loader/>;
  return (
    <div>
      <Company title="Notifications" data={products} column={column} addRoute='/notification/add'   detailRoute={{route: 'notifications',apiUrl:'notifications',title:'Notification'}} showDetailsRoute={true} showAddtionalButton={resolveDispute}/>
    </div>
  );
};

export default List;
