import React from "react";
import Form from "../../../../components/Form";
import * as yup from "yup";

const Add = () => {


  const schema = yup.object({
    current_balance: yup.string().required("Current Balance is required."),
    balance_hold: yup.string().required("Balance Hold is required."),
    last_transection_date: yup.string().required("Last Transaction Date is required."),
    walletable_type: yup.string().required("Wallet Type is required."),
    walletable_id: yup.string().required("Wallet ID is required."),
  });


  const fields = [
    {
      type: "text",
      name: "current_balance",
      placeholder: "Current Balance",
      column: 4,
    },
    {
      type: "text",
      name: "balance_hold",
      placeholder: "Balance Hold",
      column: 4,
    },
    {
      type: "datetime",
      name: "last_transection_date",
      placeholder: "Last Transaction Date",
      column: 4,
    },
    {
      type: "text",
      name: "walletable_type",
      placeholder: "Wallet Type",
      column: 4,
    },
    {
      type: "text",
      name: "walletable_id",
      placeholder: "Wallet ID",
      column: 4,
    },
  
  ];

  return (
    <Form title="Wallet" schema={schema} fields={fields} />
  );
};

export default Add;
