import React from 'react';
import { BackgroundBase } from './components/BackgroundBase';

export const Background = () => {

  return (
    <>
      <BackgroundBase />
    </>
  )
}

