import React, { useState, useEffect } from "react";
import Company from "../../../../components/Datatable";
import { ProductService } from "../../../../utils/data";
import '../../../main.css';
import '../../../flags.css';
// import apiCall from "../../../../api/Api";
import Loader from '../../../../components/Loader'

const List = () => {
  const [products, setProducts] = useState(null); 
  useEffect(() => {
    const getCustomers = async () => {
      try {
        // const response = await apiCall("GET", "employees");
        // console.log(response, 'employees response');
        // setProducts(response)
        ProductService.getProducts().then((data) => setProducts(data));
        // navigate('/companies');
      } catch (error) {
        console.error("Failed to Add:", error);
      }
    }
    getCustomers()
    
  }, []);
  
  // columns here from the API
  const column = [
    { 
      name:'id',
      title:'ID'
    },
    { 
      name:'code',
      title:'User ID'
    },
    { 
      name:'code',
      title:'Company ID'
    },
    // { 
    //   name:'status',
    //   title:'Status'
    // },
  ]; 

      // Loader here
      if (products === null) return <Loader/>;
  return (
    <div>
      <Company title="Employees" data={products} column={column} addRoute='/employee/add' detailRoute='employees'/>
    </div>
  );
};

export default List;
