import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import { FiSettings } from 'react-icons/fi';
// import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { Navbar, Footer, Sidebar, ThemeSettings } from './components';
import { Orders, Calendar, Stacked, Pyramid, Customers,CustomerAdd,
  CustomerDetail, Kanban, Line, Area, Bar, 
  Pie, Financial, ColorPicker, ColorMapping, Editor,
  Company,CompanyAdd,CompanyDetail ,
  Skills,SkillAdd,SkillDetail, SubSkills,
  Jobs,JobAdd,JobDetail,
  Peoples,PeopleAdd,PeopleDetail, 
  Geeks,GeekAdd,GeekDetail, 
  Addressess, AddressAdd, AddressDetail, 
  Schedules,  ScheduleAdd,  ScheduleDetail, 
  GeekJobs,  GeekJobAdd,  GeekJobDetail, 
  Wallets,  WalletAdd,  WalletDetail,
  WalletTransactions,  AllTransactions, 
  WithdrawalRequests, WithdrawalRequestDetail, WithdrawalRequestAdd, 
  Notifications, NotificationDetail, NotificationAdd, 
  Users, UserDetail, UserAdd, 
  Employees,EmployeeAdd,EmployeeDetail,GeekSkills,
  SettingAdd,
  GeekReviews,Disputes,Fines,
  Bookings,
  BookingAdd,
  BookingDetail,
  MainSkills,
  Roles,RoleAdd,RoleDetail,Ecommerce,
  MainCategoryAdd,Settings
} from './pages';
import './App.css';

import { useStateContext } from './contexts/ContextProvider';
import {Auth} from './pages/Authentication/src/Auth';

import OnBoarding from './pages/CompanyBoarding/src/components/OnBoarding';

const App = () => {
  const { setCurrentColor, setCurrentMode, currentMode, activeMenu, themeSettings } = useStateContext();
  const user = JSON.parse(localStorage.getItem('user'));
  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  return (
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
      <BrowserRouter>
        <div className="flex relative dark:bg-main-dark-bg">
        {
             !user ? <Auth/> : <> <div className="fixed right-4 bottom-4" style={{ zIndex: '1000' }}>
              {/* <TooltipComponent
                content="Settings"
                position="Top"
              >
                <button
                  type="button"
                  onClick={() => setThemeSettings(true)}
                  style={{ background: currentColor, borderRadius: '50%' }}
                  className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                >
                  <FiSettings />
                </button>
  
              </TooltipComponent> */}
            </div>
            {activeMenu ? (
              <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
                <Sidebar  currentUser = {user}/>
              </div>
            ) : (
              <div className="w-0 dark:bg-secondary-dark-bg">
                <Sidebar  currentUser = {user}/>
              </div>
            )}
            <div
              className={
                activeMenu
                  ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  '
                  : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
              }
            >
              <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
                <Navbar />
              </div>
              <div>
                {themeSettings && (<ThemeSettings />)}
  
                <Routes>
                  {/* dashboard  */}
                  {/* <Route path="/" element={(<Ecommerce />)} /> */}
                  <Route path="/dashboard" element={(<Ecommerce />)} />
  
                  <Route path="/onboarding" element={<OnBoarding />} />

                  {/* pages  */}
                  
                  <Route path="/orders" element={<Orders />} />
                  <Route path="/settings" element={<SettingAdd />} />
                  <Route path="/settings/:name" element={<Settings />} />
                  <Route path="/disputes" element={<Disputes />} />
                  <Route path="/fines" element={<Fines/>} />

                  <Route path="/Employees" element={<Employees />} />
                  <Route path="/Employee/add" element={<EmployeeAdd />} />
                  <Route path="/Employee/:id/detail" element={<EmployeeDetail />} />

                  <Route path="/customers" element={<Customers />} />
                  <Route path="/customer/add" element={<CustomerAdd />} />
                  <Route path="/customers/:id/detail" element={<CustomerDetail />} />

                  <Route path="/roles" element={<Roles />} />
                  <Route path="/role/add" element={<RoleAdd />} />
                  <Route path="/roles/:id/detail" element={<RoleDetail />} />


                  <Route path="/categories" element={<Skills />} />
                  <Route path="/main-categories" element={<MainSkills />} />
                  <Route path="/sub-categories/:id" element={<SubSkills />} />
                  <Route path="/categories/add" element={<SkillAdd />} />
                  <Route path="/main-categories/add" element={<MainCategoryAdd />} />
                  <Route path="/categories/:id/edit" element={<SkillAdd />} />
                  <Route path="/categories/:id/detail" element={<SkillDetail />} />

                     {/* Users */}
                  <Route path="/users" element={<Users/>} />
                  <Route path="/user/add" element={<UserAdd/>} />
                  <Route path="/user/:id/detail" element={<UserDetail/>} />

                   {/* Notifications */}
                   <Route path="/notifications" element={<Notifications/>} />
                  <Route path="/notification/add" element={<NotificationAdd/>} />
                  <Route path="/notification/:id/detail" element={<NotificationDetail/>} />


                  {/* WithdrawalRequests */}
                  <Route path="/withdrawal-requests" element={<WithdrawalRequests/>} />
                  <Route path="/withdrawal-request/add" element={<WithdrawalRequestAdd/>} />
                  <Route path="/withdrawal-request/:id/detail" element={<WithdrawalRequestDetail/>} />

                   {/* Wallet Transactions */}
                  <Route path="/transactions" element={<AllTransactions/>} />
                  {/* <Route path="/wallet-transaction/add" element={<WalletTransactionAdd/>} />
                  <Route path="/wallet-transaction/:id/detail" element={<WalletTransactionDetail/>} />  */}

                   {/* Wallets */}
                  <Route path="/wallets" element={<Wallets/>} />
                  <Route path="/wallet/add" element={<WalletAdd/>} />
                  <Route path="/wallet/:id/detail" element={<WalletDetail/>} />
                  <Route path="/wallet-transactions/:id" element={<WalletTransactions/>} />


                  {/* Geek Jobs */}
                  <Route path="/geek-jobs" element={<GeekJobs/>} />
                  <Route path="/geek-job/add" element={<GeekJobAdd/>} />
                  <Route path="/geek-job/:id/detail" element={<GeekJobDetail/>} />

                   {/* Schedules */}
                   <Route path="/schedules" element={<Schedules/>} />
                  <Route path="/schedule/add" element={<ScheduleAdd/>} />
                  <Route path="/schedule/:id/detail" element={<ScheduleDetail/>} />

                  {/* Addressess */}
                  <Route path="/addresses" element={<Addressess/>} />
                  <Route path="/address/add" element={<AddressAdd/>} />
                  <Route path="/address/:id/detail" element={<AddressDetail/>} />

                   {/* Jobs */}
                  <Route path="/jobs" element={<Jobs/>} />
                  <Route path="/job/add" element={<JobAdd/>} />
                  <Route path="/jobs/:id/detail" element={<JobDetail/>} />

                  {/* Bookings */}
                  <Route path="/bookings" element={<Bookings/>} />
                  <Route path="/booking/add" element={<BookingAdd/>} />
                  <Route path="/bookings/:id/detail" element={<BookingDetail/>} />


                  {/* Geeks */}
                  <Route path="/geeks" element={<Geeks/>} />
                  <Route path="/geek/add" element={<GeekAdd/>} />
                  <Route path="/geeks/:id/detail" element={<GeekDetail/>} />
                  <Route path="/geek-skills/:id" element={<GeekSkills/>} />
                  <Route path="/geek-reviews/:id" element={<GeekReviews/>} />



                  {/* People */}
                  <Route path="/peoples" element={<Peoples/>} />
                  <Route path="/people/add" element={<PeopleAdd/>} />
                  <Route path="/people/:id/detail" element={<PeopleDetail/>} />

                  {/* Company */}
                  <Route path="/companies" element={<Company/>} />
                  <Route path="/company/add" element={<CompanyAdd/>} />
                  <Route path="/companies/:id/detail" element={<CompanyDetail/>} />
  
                  {/* apps  */}
                  <Route path="/kanban" element={<Kanban />} />
                  <Route path="/editor" element={<Editor />} />
                  <Route path="/calendar" element={<Calendar />} />
                  <Route path="/color-picker" element={<ColorPicker />} />
  
                  {/* charts  */}
                  <Route path="/line" element={<Line />} />
                  <Route path="/area" element={<Area />} />
                  <Route path="/bar" element={<Bar />} />
                  <Route path="/pie" element={<Pie />} />
                  <Route path="/financial" element={<Financial />} />
                  <Route path="/color-mapping" element={<ColorMapping />} />
                  <Route path="/pyramid" element={<Pyramid />} />
                  <Route path="/stacked" element={<Stacked />} />
  
                </Routes>
              </div>
              <Footer />
            </div></>
        }
        </div> 
      </BrowserRouter>
    </div>
  );
};

export default App;
