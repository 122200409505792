import React, { useState, useEffect } from "react";
import Company from "../../../../components/Datatable";
import { ProductService } from "../../../../utils/data";
import '../../../main.css';
import '../../../flags.css';

const List = () => {
  const [products, setProducts] = useState(null);
  useEffect(() => {
    ProductService.getProducts().then((data) => setProducts(data));
  }, []);
  
  // columns here from the API
  const column = [
    { 
      name:'code',
      title:'ID'
    },
    { 
      name:'withdrawaable_id',
      title:'Withdrawaable ID'
    },
    { 
      name:'withdrawaable_type',
      title:'Withdrawaable Type'
    },
    { 
      name:'status',
      title:'Status'
    },
   
  ]; 

      // Loader here
  if (products === null) return <p>Loading!!</p>;
  return (
    <div>
      <Company title="Withdrawal Requests" data={products} column={column} addRoute='/withdrawal-request/add' detailRoute='withdrawal-request'/>
    </div>
  );
};

export default List;
