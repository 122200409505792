import React, { useState, useEffect } from "react";
import Company from "../../../../components/Datatable";
// import { ProductService } from "../../../../utils/data";
import '../../../main.css';
import '../../../flags.css';
import { Button } from 'primereact/button';

const List = () => {
  const [products, setProducts] = useState(null);

  const data =  [
    {
        id: '1000',
        code: 'f230fh0g3',
        job_id: '1450',
        geek_id: '57',
        description: 'I want Refund, Job is not done accordingly',
        name: 'Bamboo Watch',
        price: 65,
        status:'Pending',
    },
    {
        id: '1001',
        code: 'nvklal433',
        name: 'Black Watch',
        job_id: '1450',
        geek_id: '57',
        description: 'I want Refund, Job is not done accordingly',
        price: 72,
        status:'Paid'

    },
    {
        id: '1002',
        code: 'zz21cz3c1',
        name: 'Blue Band',
        job_id: '1450',
        geek_id: '57',
        description: 'I want Refund, Job is not done accordingly',
        price: 79,
        status:'Pending'

    },
    {
        id: '1003',
        code: '244wgerg2',
        name: 'Blue T-Shirt',
        job_id: '1450',
        geek_id: '57',
        description: 'I want Refund, Job is not done accordingly',
        price: 29,
        status:'Pending'
    },
    {
        id: '1004',
        code: 'h456wer53',
        name: 'Bracelet',
        job_id: '1450',
        geek_id: '57',
        description: 'I want Refund, Job is not done accordingly',
        price: 15,
         status:'Pending'
    },
    {
        id: '1005',
        code: 'av2231fwg',
        name: 'Brown Purse',
        job_id: '1450',
        geek_id: '57',
        description: 'I want Refund, Job is not done accordingly',
        price: 120,
        status:'Paid'
    },
    {
        id: '1006',
        code: 'bib36pfvm',
        name: 'Chakra Bracelet',
        job_id: '1450',
        geek_id: '57',
        description: 'I want Refund, Job is not done accordingly',
        price: 32,
        status:'Paid'

    },
    {
        id: '1007',
        code: 'mbvjkgip5',
        name: 'Galaxy Earrings',
        job_id: '1450',
        geek_id: '57',
        description: 'I want Refund, Job is not done accordingly',
        price: 34,
        status:'Paid'

    },
    {
        id: '1008',
        code: 'vbb124btr',
        name: 'Game Controller',
        job_id: '1450',
        geek_id: '57',
        description: 'I want Refund, Job is not done accordingly',
        price: 99,
        status:'Paid'

    },
    {
        id: '1009',
        code: 'cm230f032',
        name: 'Gaming Set',
        job_id: '1450',
        geek_id: '57',
        description: 'I want Refund, Job is not done accordingly',
        price: 299,
        status:'Paid'

    },
   
];

  useEffect(() => {
    setProducts(data);
  }, []);
  
  // columns here from the API
  const column = [
    { 
      name:'code',
      title:'ID'
    },
    { 
      name:'code',
      title:'Job ID'
    },
    { 
      name:'code',
      title:'Geek ID'
    },
    { 
      name:'name',
      title:'Customer Name'
    },
    { 
      name:'code',
      title:'Company ID'
    },
    { 
      name:'description',
      title:'Reason'
    },
    { 
      name:'price',
      title:'Fine($)'
    },
    { 
      name:'status',
      title:'Status',
      type:'status'
    },
    
    
  ]; 

  const handleDispute = (rowData)  => {
    console.log('clicked',rowData)
  }


  const resolveDispute = (rowData) =>  <Button icon="pi pi-id-card" rounded outlined className="m-1" onClick={() => handleDispute(rowData)} />

      // Loader here
  if (products === null) return <p>Loading!!</p>;
  return (
    <div>
      <Company title="Fines" data={products} column={column} detailRoute='fines' showDetailsRoute={true} showAddtionalButton={resolveDispute} />
    </div>
  );
};

export default List;
