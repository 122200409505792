import React from 'react';
import ReactDOM from 'react-dom';
import { ContextProvider } from './contexts/ContextProvider';
import { PrimeReactProvider } from 'primereact/api';
import './index.css'; // Your Tailwind CSS file
// import 'bootstrap/dist/css/bootstrap.min.css';

import App from './App';

// PRIME REACT CSS
import 'primereact/resources/themes/lara-light-indigo/theme.css'; // PrimeReact theme
import 'primereact/resources/primereact.min.css'; // PrimeReact core styles
import 'primeicons/primeicons.css'; // PrimeIcons for icons
import 'primeflex/primeflex.css'; // PrimeFlex for layout utilities




if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('../firebase-messaging-sw.js')
      .then(function (registration) {
          console.log('Registration successful, scope is:', registration.scope);
      }).catch(function (err) {
          console.log('Service worker registration failed, error:', err);
      });
}

ReactDOM.render(
  <React.StrictMode>
   <PrimeReactProvider>
    <ContextProvider>
      <App />
    </ContextProvider>
   </PrimeReactProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
