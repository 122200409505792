import React,{useEffect,useState} from 'react';
// import { BsCurrencyDollar } from 'react-icons/bs';
// import { GoPrimitiveDot } from 'react-icons/go';
import { LineChart, Loader } from '../components';
import { recentTransactions, getEarningData } from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';
import Bar from '../components/Charts/Bar';
import Line from '../components/Charts/Line';
import apiCall from '../api/Api';
import { useNavigate } from 'react-router-dom';
import { Calendar } from "primereact/calendar";




const Ecommerce = () => {
  const { currentMode } = useStateContext();
  const [earningData, setEarningData] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [loader, setloader] = useState(false);
  const [startValue, setStartValue] = useState(null);
  const [endValue, setEndValue] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [barChartLabels, setBarChartLabels] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  let navigate = useNavigate()
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        setloader(true)
        if(startDate !== null && endDate !== null){
           response = await apiCall("POST", '/dashboard/counts',{start_date:startDate,endDate});
        }else{
          response = await apiCall("POST", '/dashboard/counts');
        }
        const mappedData = getEarningData(response.data);
        setEarningData(mappedData);
        setBarChartLabels(response?.data?.jobs_per_month?.labels)
        setBarChartData(response?.data?.jobs_per_month?.data)
        setloader(false)

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [startDate,endDate]);


const DropDown = ({title,value,name}) => (
  // <div className="w-100 lg:w-48 border border-color px-2 py-1 rounded-md">
 <Calendar
      id="time"
      value={value}
      name={name}
      onChange={(date) => dateHandler(date,name)}  // Handle date selection
      style={{ border: 'none', color: currentMode === 'Dark' ? 'white' : 'black' }}
      placeholder={title}
      // className="w-full" // Ensure full width in parent div
      showIcon  // Show calendar icon
      dateFormat="mm-dd-yy" // Customize date format
      className="rounded border-[#AEBBCD] w-[25rem] focus:outline-none focus:ring-1 mt-3"
      // showTime
      // hourFormat="12"
    />
  // </div>
);


  const dateHandler= (value,name) => {
    console.log(value,'value')
    console.log(name==="start_name",'name')
    console.log(name,'name')
    const date = new Date(value?.value);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-based index
    const year = date.getFullYear();
    const formattedDate = `${year}-${month}-${day}`;
    console.log(formattedDate)
    if(name==="start_date"){
      setStartValue(value?.value)
      setStartDate(formattedDate) 
    }else {
      setEndValue(value?.value)
      setEndDate(formattedDate)
    }
    }

  if (loader === true) return <Loader/>;

  return (
  <>
    <div className="flex justify-end gap-3 items-center mt-5">
    <p className="text-xl font-semibold">Select Filter</p>
    <DropDown title="Start Date" value={startValue} name='start_date'/> --
    <DropDown title="End Date" value={endValue} name='end_date'/>
  </div>
    <div className="mt-20">
    
      {/* Top Section - Cards */}
      <div className="flex flex-wrap justify-center gap-16 mb-10">
        {/* Cards */}
        {earningData?.map((item, index) => (
          <div
            key={index}
            className={` ${item.cardColor} h-32 dark:text-gray-200 dark:bg-secondary-dark-bg sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 p-4 pt-9 rounded-xl cursor-pointer`}
            onClick={()=> navigate(item?.route)}
          >
            <div
              // type="button"
              style={{ color: item.iconColor, backgroundColor: item.iconBg,height:'32%',width:'fit-content' }}
              className="text-2xl opacity-0.9 rounded-full hover:drop-shadow-xl"
            >
              {item.icon}
            </div>
            <p className="mt-1">
              <span className="text-lg font-semibold">{item.amount}</span>
              <span className={`text-sm text-${item.pcColor} ml-2`}>
                {item.percentage}
              </span>
            </p>
            <p className="text-sm text-white mt-1">{item.title}</p>
          </div>
        ))}
      </div>

     {/* Middle Section - Graphs */}
      <div className="flex flex-wrap justify-center gap-6 lg:gap-10 m-5">
          {/* Graphs in two columns */}
          <div className="flex flex-wrap lg:flex-nowrap gap-6 lg:gap-10 w-full">
            <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg p-6 rounded-xl lg:w-1/2 shadow-lg">
              {/* <div className="flex justify-between items-center mb-8">
                <p className="text-xl font-semibold">Revenue Updates</p>
                <DropDown currentMode={currentMode} value='1'  />
              </div> */}
              <div className="space-y-4">
                <div>
                <Line/>
                </div>
              </div>
            </div>

            <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg p-6 rounded-xl lg:w-1/2 shadow-lg">
              {/* <div className="flex justify-between items-center mb-8">
                <p className="text-xl font-semibold">Jobs</p>
                <DropDown currentMode={currentMode} value='2' />
              </div> */}
              <div className="space-y-4">
                <div>
                <Bar labels={barChartLabels} barData={barChartData}/>
                </div>
              </div>
            </div>
          </div>
      </div>


     {/* Bottom Section - Tables */}
      <div className="flex justify-center gap-10 mt-10 m-5">
        {/* Sales Overview */}
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg p-6 rounded-xl w-full lg:w-1/2 shadow-lg">
          {/* <div className="flex justify-between items-center mb-10">
            <p className="text-xl font-semibold">Sales Overview</p>
            <DropDown currentMode={currentMode} value='3' />
          </div> */}
          <LineChart />
        </div>

        {/* Recent Transactions */}
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg p-6 rounded-xl w-full lg:w-1/2 shadow-lg">
          {/* <div className="flex justify-between items-center mb-10">
            <p className="text-xl font-semibold">Recent Transactions</p>
            <DropDown currentMode={currentMode} value='1' />
          </div> */}
          <div className="overflow-auto max-h-96">
            {recentTransactions.map((item) => (
              <div key={item.title} className="flex justify-between mt-4 border-b pb-4">
                <div className="flex gap-4">
                  <div
                    // type="button"
                    style={{ color: item.iconColor, backgroundColor: item.iconBg,width:'fit-content' }}
                    className="text-2xl rounded-lg p-4 hover:drop-shadow-xl"
                  >
                    {item.icon}
                  </div>
                  <div>
                    <p className="text-md font-semibold">{item.title}</p>
                    <p className="text-sm text-gray-400">{item.desc}</p>
                  </div>
                </div>
                <p className={`text-${item.pcColor}`}>{item.amount}</p>
              </div>
            ))}
          </div>
        </div>
      </div>


    </div>
    </>
  );
};

export default Ecommerce;
