import React from 'react'
import { Routes, Route } from 'react-router-dom';
import {Background} from './Background'
import './global.css';
import SignIn from './components/Forms/SignIn';
import SignUp from './components/Forms/SignUp';
import ForgotPassword from './components/Forms/ForgotPassword';
import ChangePassword from './components/Forms/ChangePassword';

             
export const Auth = () => {
  return (
    <div className='auth-content'>
     <Background />
     <Routes>
                <Route index path="/" element={(<SignIn />)} />
                <Route path="/company_sign_in" element={(<SignIn />)} />
                <Route path="/signup" element={(<SignUp />)} />
                <Route path="/forgotpassword" element={<ForgotPassword />} />
                <Route path="/changepassword" element={<ChangePassword />} />
      </Routes>
    </div>
  )
}
