import React, { useEffect,useState } from 'react';
import { FaBars,FaBell } from 'react-icons/fa';
// import { FiShoppingCart } from 'react-icons/fi';
// import { BsChatLeft } from 'react-icons/bs';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import avatar from '../data/avatar.jpg';
import { Notification, UserProfile } from '.';
import { useStateContext } from '../contexts/ContextProvider';
import apiCall from '../api/Api';

const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={() => customFunc()}
      style={{ border: 'none', color:'gray', cursor:'pointer' }}
      className="relative text-900 rounded-full p-2 hover:bg-light-gray"
    >
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
      />
      {icon}
    </button>
  </TooltipComponent>
);

const Navbar = () => {
  const { currentColor, activeMenu, setActiveMenu, handleClick, isClicked, setScreenSize, screenSize } = useStateContext();
  const [user, setUser] = useState();
  // let user = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {

    const getLoggedInUser = async () => {

      try {
            const response = await apiCall("GET", `get-user-profile`);
            setUser(response?.data)
      } catch (error) {
        localStorage.clear()
        window.location.href='/'
        window.location.reload()
      }

    }
  


    getLoggedInUser()

  }, [])
  


  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize]);

  const handleActiveMenu = () => setActiveMenu(!activeMenu);

  return (
    <div className="flex justify-between p-2 md:ml-6 md:mr-6 relative">
    <NavButton title="Menu" customFunc={handleActiveMenu} color={currentColor} icon={<FaBars />} />
    <div className="flex items-center gap-4">
      {/* <NavButton title="Cart" customFunc={() => handleClick('cart')} color={currentColor} icon={<FiShoppingCart />} /> */}
      {/* <NavButton title="Chat" dotColor="#03C9D7" customFunc={() => handleClick('chat')} color={currentColor} icon={<BsChatLeft />} /> */}
      <NavButton 
        title="Notification" 
        dotColor="rgb(254, 201, 15)" 
        customFunc={() => handleClick('notification')} 
        color={currentColor} 
        icon={<FaBell />} 
      />
      <TooltipComponent content="Profile" position="BottomCenter">
        <div
          className="flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg"
          onClick={() => handleClick('userProfile')}
        >
          <img
            className="rounded-full h-8"
            src={avatar}
            alt="user-profile"
          />
          <p>
            <span className="text-gray-400 text-14">Hi,</span>{' '}
            <span className="text-gray-400 font-bold ml-1 text-14">
              {user?.user?.first_name + user?.user?.last_name}
            </span>
          </p>
          <MdKeyboardArrowDown className="text-gray-400 text-14" />
        </div>
      </TooltipComponent>
  
      {/* {isClicked.cart && (<Cart />)} */}
      {/* {isClicked.chat && (<Chat />)} */}
      {isClicked.notification && (<Notification />)}
      {isClicked.userProfile && (<UserProfile user={user} />)}
    </div>
  </div>
  

  );
};

export default Navbar;
