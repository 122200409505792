import React from 'react';
import { Card } from 'primereact/card';
// import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import DetailRow from './DetailRow.jsx';


const dateformat = (data) => {
const date = new Date(data);
const options = { day: 'numeric', month: 'long', year: 'numeric' };
const formattedDate = date.toLocaleDateString('en-GB', options);
return formattedDate
}

export const Overview = ({jobDetailData}) => (
  <Card className="shadow-lg rounded-lg bg-white">
    <h2 className="text-xl font-bold mb-2">Overview</h2>
    <div className="text-sm">
      <DetailRow label="Job Title:" value="Job Title" />
      {/* <DetailRow label="Experience:" value="0-2 Years" /> */}
      <DetailRow label="Vacancy:" value="1" />
      {/* <DetailRow
        label="Job Type:"
        value={<Tag value="Full Time" className="bg-green-100 text-green-800 px-2 py-1 rounded-md" />}
      /> */}
      <DetailRow
        label="Status:"
        value={<Tag value={jobDetailData?.status} className="bg-blue-100 text-blue-800 px-2 py-1 rounded-md capitalize" />}
      />
      <DetailRow label="Posted Date:" value={dateformat(jobDetailData?.created_at)} />
      <DetailRow label="Has Part" value={jobDetailData?.has_parts} />
    </div>

    {/* Action Buttons */}
    {/* <div className="mt-2 flex justify-between">
      <Button label="Apply Now" className="p-button-outlined p-button-primary w-1/2 mr-2" />
      <Button label="Contact Us" className="p-button-outlined p-button-danger w-1/2 ml-2" />
    </div> */}
  </Card>
);

