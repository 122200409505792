import React, { useState,useEffect } from "react";
import Form from "../../../../components/Form";
import * as yup from "yup";
import apiCall from "../../../../api/Api";

const Add = () => {
  const [user_id, setUserId] = useState(null);
  const [company_id, setCompanyId] = useState(null);
  const [companiesOption, setCompaniesOptions] = useState(null);
  const [usersOption, setUserOptions] = useState(null);

  useEffect(() => {
    getOption('employees',setUserOptions)
    getOption('companies',setCompaniesOptions)
  }, [])

  const  getOption = async (url,setFun) => {
    try {
      const response = await apiCall('GET', url);
      console.log(response,'response avvv')
      // setFun(response)
      // return response
    } catch (error) {
      console.error("Failed to Add Options:", error);
    }
  }
  

  const onUserSelectChange = (val) => {
    console.log(val, "users");
    setUserId(val);
  };

  const onCompanySelectChange = (val) => {
    console.log(val, "company");
    setCompanyId(val);
  };

  const schema = yup.object({
    user_id: yup.string().required("User is required."),
    company_id: yup.string().required("Company is required."),

  });



  const fields = [
   
    {
      type: "dropdown",
      name: "user_id",
      placeholder: "Users",
      column: 4,
      onChange: onUserSelectChange,
      value: user_id,
      options:usersOption
    },
    {
      type: "dropdown",
      name: "company_id",
      placeholder: "Companies",
      column: 4,
      onChange: onCompanySelectChange,
      value: company_id,
      options:companiesOption
    },
   
  ];
  // if(peopleOption === null && usersOption === null) return <p>Loading!!!!</p>
  // else  return (
  return  <Form title="Employee" schema={schema} fields={fields} />
  // );
};

export default Add;
