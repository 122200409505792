import React, { useState, useEffect } from "react";
import Company from "../../../../components/Datatable";
import { ProductService } from "../../../../utils/data";
import '../../../main.css';
import '../../../flags.css';

const List = () => {
  const [products, setProducts] = useState(null);
  useEffect(() => {
    ProductService.getProducts().then((data) => setProducts(data));
  }, []);
  
  // columns here from the API
  const column = [
    { 
      name:'code',
      title:'ID'
    },
    { 
      name:'code',
      title:'Geek ID'
    },
    { 
      name:'jobs_id',
      title:'Job ID'
    },
    // { 
    //   name:'start_time',
    //   title:'Start Time'
    // },
    // { 
    //   name:'end_time',
    //   title:'End Time'
    // },
    { 
      name:'status',
      title:'Status'
    },
    { 
      name:'total_bill',
      title:'Total Bill'
    },
    { 
      name:'total_paid',
      title:'Total Paid'
    },
   
  ]; 

      // Loader here
  if (products === null) return <p>Loading!!</p>;
  return (
    <div>
      <Company title="Geek Jobs" data={products} column={column} addRoute='/geek-job/add' detailRoute='geek-job'/>
    </div>
  );
};

export default List;
