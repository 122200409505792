import React from "react";
import CustomCard from "../../../../components/Card";

const Add = () => {

  return (
    // <Form title="Global Setting" schema={schema} fields={fields} />
    <div className="flex justify-center mt-5" style={{height:'80vh'}}>
    <CustomCard 
     img={'https://img.freepik.com/free-vector/man-working-laptop-with-coffee-stationary-cartoon-vector-illustration_138676-2206.jpg?size=338&ext=jpg&ga=GA1.1.2008272138.1724025600&semt=ais_hybrid'}
     title='Geeks Settings'
     subTitle='Manage Commissions, Referal system, Fines on the Jobs compeleted by geeks.'
     name='geeks'
     />
    <CustomCard 
    img={'https://static.vecteezy.com/system/resources/previews/007/223/902/non_2x/bussinesman-with-money-and-suitcase-cartoon-icon-illustration-people-bussines-icon-concept-isolated-premium-vector.jpg'}
     title='Customers Settings'
     subTitle='Manage Commissions, Referal system, Fines on the Jobs compeleted by customers.'
     name='customer'
    />
    
    <CustomCard
    img={'https://png.pngtree.com/template/20191014/ourmid/pngtree-real-estate-logo-design-template-building-logo-image_317801.jpg'}
    title={'Companies Settings'}
    subTitle={'Manage Commissions and Fines on the Jobs compeleted by companies.'}
    name='company'

    />
    </div>
    
  );
};

export default Add;
