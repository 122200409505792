import React, { useState, useEffect } from "react";
import Company from "../../../../components/Datatable";
import { ProductService } from "../../../../utils/data";
import '../../../main.css';
import '../../../flags.css';
// import apiCall from "../../../../api/Api";
import { useParams,useLocation } from "react-router-dom";
import Loader from '../../../../components/Loader'

const GeekReviews = () => {
    const {id} = useParams()

  const [products, setProducts] = useState(null);
  const [GeekName] = useState(null);
  const location = useLocation();
  const { geekName } = location && location?.state;
  console.log(geekName,'geekName')
  useEffect(() => {
    const getGeekReviews = async () => {
      try {
        ProductService.getProducts().then((data) => setProducts(data));
        // const response = await apiCall("GET", `geek-reviews/${id}`);
        // console.log(response,'response')
        // const result = response.find(item => item.id === parseInt(id));
        // console.log(result,'result')
        // setGeekName(result?.first_name)
        // setProducts(result?.geek_skills)
      } catch (error) {
        console.error("Failed to Add:", error);
      }
    }
    getGeekReviews()
    
  }, [id]);
  
  // columns here from the API
  const column = [
    {
      name: "id",
      title: "ID",
    },
    {
      name: "name",
      title: "Customer Name",
    },
    {
      name: "rating",
      title: "Rating",
    },
    {
      name: "rating",
      title: "Review",
      type:'rating'
    },
  ]; 

      // Loader here
  if (products === null) return <Loader/>;
  return (
    <div>
      <Company title={GeekName ? `${GeekName} Reviews` : 'Geek Reviews'} data={products} column={column} showFurtherDetailButton={false} />
    </div>
  );
};

export default GeekReviews;
