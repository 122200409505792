import React, { useState } from 'react';
import { useForm,Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
// import { classNames } from 'primereact/utils';
import { Calendar } from "primereact/calendar";
// import { useNavigate } from "react-router-dom";
import apiCall from '../api/Api'
// import { ToggleButton } from "primereact/togglebutton";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { InputSwitch } from 'primereact/inputswitch';
import { useNavigate } from 'react-router-dom';
// import Button from 'react-bootstrap/Button';
// import 'bootstrap/dist/css/bootstrap.min.css';  
import Autocomplete from "react-google-autocomplete";

 const Form = ({redirectUrl,schema,title,fields,apiRoute}) => {
  const [switchValue, setSwitchValue] = useState()
  const [selectedDate, setSelectedDate] = useState()
  const navigate = useNavigate();
  const {register,control,setValue, handleSubmit,formState: { errors }} = useForm({ resolver: yupResolver(schema) });


  const onSubmit = async (data) => {
    console.log("Data:",data);
    
    try {
      const response = await apiCall("POST", apiRoute, data);
      console.log(response, 'response');
      if(response){
        navigate(`/${redirectUrl}`);
      }
    } catch (error) {
      console.error("Failed to Add:", error);
    }
  };


  const footer = <div className="flex h-[calc(20vh-100px)] justify-start items-center outline-none gap-3">
    <Button  label="Cancel" className={`rounded bg-[#dc3545] text-[#F5F7FF] w-[12rem] p-3 mt-2 hover:bg-red-400 mb-5`}/>
    <Button  type='submit' label="Create" className={`rounded bg-[#198754] text-[#F5F7FF] w-[12rem] p-3 mt-2 hover:bg-green-400 mb-5`}/>
    {/* <Button className='w-25' type='submit' variant="outline-success">Submit</Button>
    <Button className='w-25' variant="outline-danger">Cancel</Button> */}
  </div>

const getFormErrorMessage = (name) => {
  return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
};

const dateHandler= (field,value) => {
console.log(field,'field')
console.log(value,'value')
setSelectedDate(value)
const date = new Date(value);

// Extract the day, month, and year
const day = String(date.getDate()).padStart(2, '0');
const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-based index
const year = date.getFullYear();

// Format as dd-mm-yyyy
const formattedDate = `${year}-${month}-${day}`;
field.onChange(formattedDate)

// console.log(formattedDate); // Output: 15-08-2024

}

const   onToggleHandler = (field,e,value)  => {
  console.log(e,'e')
  console.log(value,'value')
  setSwitchValue(e.value)
  let result;
  if(value?.expected === 'number'){
    result = e.value === true ? 1 : 0
    setValue(value?.name,Number(result))
  }else{
    result = e.value === true ? true : false
    setValue(value?.name,new Boolean(result))
  }
}

  const body = (value) => {
    switch (value.type) {
      case "text":
        return (
          <Col xs={12} md={4} xl={4} className="mb-5">
            <FloatLabel>
              <InputText
                {...register(value.name)}
                type={value.type}
                className={
                  errors[value.name]
                    ? "block peer rounded-[5px] w-[25rem] m-1 mt-3 border-[#C93B32] focus:outline-none focus:border-[#C93B32]  focus:ring-1 focus:ring-[#C93B32]"
                    : "block peer rounded-[5px] border-[#AEBBCD] w-[25rem] mt-3 m-1 focus:outline-none focus:ring-1"
                }
              />
              <label htmlFor="username" style={{ width: "200px" }}>
                {value.placeholder}
              </label>
            </FloatLabel>
            <span className="place-self-start text-[14px] text-[#C93B32]">
              {errors[value.name]?.message}
            </span>
          </Col>
        );
        case "email":
          return (
            <Col xs={12} md={4} xl={4} className="mb-5">
              <FloatLabel>
                <InputText
                  {...register(value.name)}
                  type={value.type}
                  className={
                    errors[value.name]
                      ? "block peer rounded-[5px] w-[25rem] m-1 mt-3 border-[#C93B32] focus:outline-none focus:border-[#C93B32]  focus:ring-1 focus:ring-[#C93B32]"
                      : "block peer rounded-[5px] border-[#AEBBCD] w-[25rem] mt-3 m-1 focus:outline-none focus:ring-1"
                  }
                />
                <label htmlFor="username" style={{ width: "200px" }}>
                  {value.placeholder}
                </label>
              </FloatLabel>
              <span className="place-self-start text-[14px] text-[#C93B32]">
                {errors[value.name]?.message}
              </span>
            </Col>
          );

        case "number":
          return (
            <Col xs={12} md={4} xl={4} className="mb-5">
              <FloatLabel>
                <InputText
                  {...register(value.name)}
                  type={value.type}
                  className={
                    errors[value.name]
                      ? "block peer rounded-[5px] w-[25rem] m-1 mt-3 border-[#C93B32] focus:outline-none focus:border-[#C93B32]  focus:ring-1 focus:ring-[#C93B32]"
                      : "block peer rounded-[5px] border-[#AEBBCD] w-[25rem] mt-3 m-1 focus:outline-none focus:ring-1"
                  }
                />
                <label htmlFor="username" style={{ width: "200px" }}>
                  {value.placeholder}
                </label>
              </FloatLabel>
              <span className="place-self-start text-[14px] text-[#C93B32]">
                {errors[value.name]?.message}
              </span>
            </Col>
          );

        case "password":
          return (
            <Col xs={12} md={4} xl={4} className="mb-5">
              <FloatLabel>
                <InputText
                  {...register(value.name)}
                  type={value.type}
                  className={
                    errors[value.name]
                      ? "block peer rounded-[5px] w-[25rem] m-1 mt-3 border-[#C93B32] focus:outline-none focus:border-[#C93B32]  focus:ring-1 focus:ring-[#C93B32]"
                      : "block peer rounded-[5px] border-[#AEBBCD] w-[25rem] mt-3 m-1 focus:outline-none focus:ring-1"
                  }
                />
                <label htmlFor="username" style={{ width: "200px" }}>
                  {value.placeholder}
                </label>
              </FloatLabel>
              <span className="place-self-start text-[14px] text-[#C93B32]">
                {errors[value.name]?.message}
              </span>
            </Col>
          );

        case "location":
          return (
            <Col xs={12} md={4} xl={4} className="mb-5 mt-3">
            <FloatLabel className="w-full">
              <Controller
                name={value.name}
                control={control}
                // rules={{ required: 'Required.' }}
                render={({ field }) => (
                  <div className="flex w-full">
                    <Autocomplete
                      apiKey="AIzaSyAcA0K7xe_xdVj20bRCeW_BVn0KT2jGZGI"
                      onPlaceSelected={(place) => {
                        if (place.geometry) {
                          const latitude = place.geometry.location.lat();
                          const longitude = place.geometry.location.lng();
                          setValue('lat', latitude); // Set the lat value in the form
                          setValue('long', longitude); // Set the long value in the form
                        }
                      }}
                      types={['geocode']} // This restricts the suggestions to address-based results
                      placeholder="Enter a location"
                      className="p-inputtext p-component"
                      style={{width:350}}
                      // Ensure full width
                    />
                  </div>
                )}
              />
              <label htmlFor={value.name}>{value.placeholder}</label>
            </FloatLabel>
          </Col>
          );

      case "dropdown":
        return (
          <Col xs={12} md={6} lg={4} xl={3} className="mb-5">
            <FloatLabel className="w-full">
              <Controller
                name={value.name}
                control={control}
                // rules={{ required: 'Required.' }}
                render={({ field }) => (
                  <div className="flex w-full">
                    <Dropdown
                      id={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      options={value.options}
                      optionLabel="name"
                      focusInputRef={field.ref}
                      optionValue="code"
                      // placeholder={field.placeholder}
                      className="border border-[#AEBBCD] mt-3"
                      filter
                      showClear
                      style={{width:'350px'}}
                      
                    />
                    {getFormErrorMessage(field.name)}
                  </div>
                )}
              />
              <label htmlFor={value.name}> {value.placeholder} </label>
            </FloatLabel>
          </Col>
        );

      case "multi-select":
        return (
          <Col xs={12} md={4} xl={4} className="mb-5">
             <FloatLabel className="w-full">
          
            <Controller
              name={value.name}
              control={control}
              render={({ field }) => (
                <>
                  <MultiSelect
                    id={field.name}
                    //  name="value"
                    value={field.value}
                    options={value.options}
                    onChange={(e) => field.onChange(e.value)}
                    optionLabel="name"
                    optionValue="code"
                    placeholder={value.placeholder}
                    maxSelectedLabels={3}
                    className="w-full border border-[#AEBBCD] mt-3"
                    filter
                    showClear
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
              <label htmlFor={value.name}> {value.placeholder} </label>
            </FloatLabel>
          </Col>
        );

      case "image":
        return (
          // <div className={`col-${value.column}`}>
          <Col xs={12} md={4} xl={4} className="mb-5">
             {/* <FloatLabel> */}
            <label
              htmlFor={value.name}
              className="text-md text-black dark:text-white text-left"
            >
              {value.placeholder}
            </label>
            {/* <div className="card"> */}
              <FileUpload
                {...register(value.name)}
                // ref={{...register(value.name)}}
                name={value.name}
                url={"/api/upload"}
                multiple={value.multiple}
                accept="image/*"
                maxFileSize={1000000}
                emptyTemplate={
                  <p className="m-0">Drag and drop files to here to upload.</p>
                }
                className={
                  errors[value.name]
                    ? "block peer rounded-[5px] w-[25rem]  mt-5 border-[#C93B32] focus:outline-none focus:border-[#C93B32]  focus:ring-1 focus:ring-[#C93B32]"
                    : "block peer rounded-[5px] border-[#AEBBCD] w-[25rem] mt-5 focus:outline-none focus:ring-1"
                }
              />
            {/* </div> */}
            {/* </FloatLabel> */}
            <span className="place-self-start text-[14px] text-[#C93B32]">
              {errors[value.name]?.message}
            </span>
          </Col>
        );

      case "textarea":
        return (
          // <div className="col-xl-12">
          //   <label
          //     htmlFor={value.name}
          //     className="text-md text-black dark:text-white font-bold text-left"
          //   >
          //     {value.placeholder}
          //   </label>
          <Col xs={12} md={4} xl={4} className="mb-5">
            <FloatLabel>
            <textarea
              {...register(value.name)}
              rows="4"
              cols="80"
              className={
                errors[value.name]
                  ? "block peer rounded-[5px] w-[25rem] m-1 mt-3 border-[#C93B32] focus:outline-none focus:border-[#C93B32]  focus:ring-1 focus:ring-[#C93B32]"
                  : "block peer rounded-[5px] border-[#AEBBCD] w-[25rem] mt-3 m-1 focus:outline-none focus:ring-1"
              }
            ></textarea>
                <label htmlFor="username" style={{ width: "200px" }}>
                {value.placeholder}
              </label>
            </FloatLabel>
            <span className="place-self-start text-[14px] text-[#C93B32]">
              {errors[value.name]?.message}
            </span>
            </Col>

        );

      case "datetime":
        return (
          <Col
          xs={12}
          md={4}
          xl={4}
          className="w-full"
          style={{ display: "flex", flexDirection: "column", gap: 8 }}
        >
           <FloatLabel className="w-full">
           
            <Controller
              name={value.name}
              control={control}
              rules={{ required: "Date is required." }}
              render={({ field }) => (
                <div>
                  <Calendar
                    name={field.name}
                    // value={field.value}
                    value={selectedDate}
                    onChange={(e)=>  dateHandler(field,e?.target?.value) }
                    dateFormat="dd/mm/yy"
                    className="rounded border-[#AEBBCD] w-[25rem] focus:outline-none focus:ring-1 mt-3"
                    // showTime
                    // hourFormat="12"
                  />
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
             <label
              htmlFor={value.name}
              // className="text-md text-black dark:text-white text-left"
            >
              {value.placeholder}
            </label>
            </FloatLabel>
          </Col>
        );

      case "toggle":
        return (
          <Col
            xs={12}
            md={4}
            xl={4}
            className="ml-5 w-100"
            style={{ display: "flex", flexDirection: "column", gap: 8 }}
          >
            <label className="" htmlFor={value.name}>
              {value.placeholder}
            </label>
            <Controller
              name={value.name}
              control={control}
              render={({ field }) => (
                <div className="">
                  <InputSwitch
                    name={field.name}
                    // value={field.value || false}
                    checked={ switchValue|| false}
                    onChange={(e) => onToggleHandler(field,e,value) } 
                  />
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
          </Col>
        );

      case "break":
          return (
            <Col
              xs={12}
              md={4}
              xl={4}
              // className="ml-5 w-100"
              // style={{ display: "flex", flexDirection: "column", gap: 8 }}
            >
             <br/>
            </Col>
          );
  
      default:
        return null;
    }
  };

  

  return (
    <div style={{margin:'18px'}} className='mt-5'>
      <form onSubmit={handleSubmit(onSubmit)}>
         <Card title={`Add ${title}`} footer={footer} style={{maxWidth:'100%',width:'auto'}}>
        <div className="flex flex-wrap">
            {
                fields?.map(field => 
                    <React.Fragment key={field.name}>
                      <Row>
                         {body(field)}
                      </Row>
                    </React.Fragment>
                         )
            }
        </div>
        </Card>
      </form>
    </div>
  );
}


export default Form;