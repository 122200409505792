import React from "react";
import Form from "../../../../components/Form";
import * as yup from "yup";

const Add = () => {


  const schema = yup.object({
    addr_1: yup.string().required("Field is required."),
    addr_2: yup.string().required("Field is required."),
    city: yup.string().required("Field is required."),
    state: yup.string().required("Field is required."),
    country: yup.string().required("Field is required."),
    lat: yup.string().required("Field is required."),
    lng: yup.string().required("Field is required."),
    zip_code: yup.string().required("Field is required."),
    addressable_id: yup.string().required("Field is required."),
    addressable_type: yup.string().required("Field is required."),
  });




  const fields = [
    {
      type: "text",
      name: "addr_1",
      placeholder: "Address",
      column: 4,
    }, 
    {
      type: "text",
      name: "addr_2",
      placeholder: "Address 2",
      column: 4,
    },
    {
      type: "text",
      name: "city",
      placeholder: "City",
      column: 4,
    },
    {
      type: "text",
      name: "state",
      placeholder: "State",
      column: 4,
    },
    {
      type: "text",
      name: "country",
      placeholder: "Country",
      column: 4,
    },
    {
      type: "text",
      name: "lat",
      placeholder: "Latitude",
      column: 4,
    },
    {
      type: "text",
      name: "Langitude",
      placeholder: "Status",
      column: 4,
    },
    {
      type: "text",
      name: "zip_code",
      placeholder: "Zip",
      column: 4,
    },
    {
      type: "text",
      name: "addressable_id",
      placeholder: "Addressable ID",
      column: 4,
    },
    {
      type: "text",
      name: "addressable_type",
      placeholder: "Addressable Type",
      column: 4,
    },

 
  ];

  return (
    <Form title="Address" schema={schema} fields={fields} />
  );
};

export default Add;
