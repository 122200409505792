import React, { useState } from "react";
import SkillAdd from "./SkillAdd";

const Add = ({level,errors,control,register,setValue,main}) => {
  const [showCost, setShowCost] = useState(false);
  const [oneOffCost, setOneOffCost] = useState(false);

  const onTypeChange = (val) => {
    console.log(val, "asasasa");
    setShowCost(val)
  };

  const oneOffCostHandler = (val) => {
    console.log(val,'One Off Cost')
    setOneOffCost(val)
  }

  // const schema = yup.object({
  //   name: yup.string().required("Name is required."),
  //   // service_type: yup.number().required("Parent Category is required."),
  //   // parent_id: yup.number().required("Parent Category is required."),
  //   // parent_id: yup.number().required("Parent Category is required."),
  //   // is_featured: yup.boolean().required("Is Featured is required."),
  //   // image: yup.string().required("Image is required."),
    
  // });


  const fields = [
    {
      type: "text",
      name: "name",
      placeholder: "Name",
      column: 3,
      condition:true,
    },
    {
      type: "dropdown",
      name: "service_type",
      placeholder: "Select Type",
      customHandler:true,
      column: 3,
      options:[
        {name:'On-Site',code:1},
        {name:'Off-Site',code:2},
        {name:'Both',code:3}
      ],
      condition: level === 2
    },     
    {
      type: "toggle",
      name: "one_off_cost",
      placeholder: "One-Off Cost",
      customHandler:true,
      column: 3,
      condition:level === 2,
    },
     
    {
      type: "number",
      name: "per_hour_cost_remote",
      value:0,
      placeholder: "Per Hour Rate (OffSite)",
      column: 4,
      condition: (!oneOffCost && (showCost === 3 || showCost === 2))
    }, 
    {
      type: "number",
      name: "per_hour_cost_onsite",
      value:0,
      placeholder: "Per Hour Rate (OnSite)",
      column: 3,
      condition: !oneOffCost && (showCost === 3 || showCost === 1)
    }, 
    {
      type: "number",
      name: "one_off_cost_remote",
      value:0,
      placeholder: "Cost (OffSite)",
      column: 3,
      condition: ( oneOffCost && (showCost === 3 || showCost === 2))
    }, 
    {
      type: "number",
      name: "one_off_cost_onsite",
      value:0,
      placeholder: "Cost (OnSite)",
      column: 3,
      condition: (oneOffCost && (showCost === 3 || showCost === 1))
    }, 
    {
      type: "number",
      name: "services_fee",
      value:0,
      placeholder: "Services Fee",
      column: 3,
      condition:level === 2,
    }, 
    {
      type: "number",
      name: "max_service_fee",
      value:0,
      placeholder: "Max Services Fee",
      column: 3,
      condition:level === 2,
    }, 
    {
      type: "number",
      name: "min_service_fee",
      value:0,
      placeholder: "Min Services Fee",
      column: 3,
      condition:level === 2,
    }, 

    {
      type: "toggle",
      name: "is_featured",
      placeholder: "Is Featured",
      customHandler:false,
      column: 3,
      condition:level === 2,
    },
    { type: "heading", name: "heading", placeholder: "Media Images", column: 4 ,  condition:main},

    {
      type: "image",
      name: "image",
      placeholder: "Image",
      column: 4,
      condition:main,
    },
  ];

  return (
    <>
      
      <SkillAdd title="Service" main={main} errors={errors} fields={fields} onTypeChange={onTypeChange} oneOffCostHandler={oneOffCostHandler} control={control} register={register} setValue={setValue}/>
    </>
      
  );
};

export default Add;
