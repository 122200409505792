import React from 'react';

const DetailRow = ({ label, value }) => (
  <div className="flex justify-between items-center capitalize">
    <h3 className="text-gray-500">{label}</h3>
    <p className="font-semibold">{value}</p>
  </div>
);

export default DetailRow;
