import React, { useState, useEffect } from "react";
import Company from "../../../../components/Datatable";
import '../../../main.css';
import '../../../flags.css';
import apiCall from "../../../../api/Api";
import Loader from '../../../../components/Loader'

const List = () => {
  const [products, setProducts] = useState(null); 
  const [page, setPage] = useState(1); 
  const [perPage, setPerPage] = useState(10); 
  const [totalRecords, setTotalRecords] = useState(0);
  const [loader, setloader] = useState(false); 
  useEffect(() => {
    const getRoles = async () => {
      try {
        const response = await apiCall("GET", `role-list?page=${page}&perPage=${perPage}`);
        setTotalRecords(response.meta?.total); // Assuming your API returns total record count
        setProducts(response?.data)
      } catch (error) {
        console.error("Failed to Add:", error);
      }
    }
    getRoles()
    
  }, [page, perPage]);
  
  // columns here from the API
  const column = [
    { 
      name:'id',
      title:'ID',
      sortable:true
    },
    { 
      name:'name',
      title:'Name',
      sortable:true
    },
    // { 
    //   name:'first_name',
    //   title:'First Name'
    // },
    // { 
    //   name:'last_name',
    //   title:'Last Name'
    // },
    // { 
    //   name:'country',
    //   title:'Country'
    // },
    // { 
    //   name:'mobile',
    //   title:'Mobile'
    // },
    
    // { 
    //   name:'gander',
    //   title:'Gender'
    // },
    // { 
    //   name:'target_radius',
    //   title:'Target Raduis'
    // },
    // { 
    //   name:'status',
    //   title:'Status',
    //   type:'status'
    // },
  ]; 

   // Handle page change
   const handlePageChange = (newPage, newRowsPerPage) => {
    setPage(newPage);
    setPerPage(newRowsPerPage);
   };

      // Loader here
  if (products === null) return <Loader/>;
  return (
    <div>
      <Company 
      title="Roles" 
      data={products} 
      column={column} 
      addRoute='/role/add'  
      detailRoute={{route: 'roles',apiUrl:'role-edit'}} 
      showDetailsRoute={true}
      totalRecords={totalRecords}
      perPage={perPage}
      page={page}
      onPageChange={handlePageChange}
      loading={loader}
      />
    </div>
  );
};

export default List;
