import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import apiCall from '../../../../api/Api'

const validationSchemas = {
    // geeks: yup.object().shape({
    //     geek_fine_percentage: yup.number().nullable().default(0).required('Geek fine percentage is required'),
    //     geek_minimum_fine: yup.number().nullable().default(0).required('Geek minimum fine is required'),
    //     geek_maximum_fine: yup.number().nullable().default(0).required('Geek maximum fine is required'),
    //     geek_commission_percentage: yup.number().nullable().default(0).required('Geek commission percentage is required'),
    // }),
    // company: yup.object().shape({
    //     company_fine_percentage: yup.number().nullable().default(0).required('Company fine percentage is required'),
    //     company_minimum_fine: yup.number().nullable().default(0).required('Company minimum fine is required'),
    //     company_maximum_fine: yup.number().nullable().default(0).required('Company maximum fine is required'),
    //     company_commission_percentage: yup.number().nullable().default(0).required('Company commission percentage is required'),
    // }),
    // customer: yup.object().shape({
    //     customer_fine_percentage: yup.number().nullable().default(0).required('Customer fine percentage is required'),
    //     customer_minimum_fine: yup.number().nullable().default(0).required('Customer minimum fine is required'),
    //     customer_maximum_fine: yup.number().nullable().default(0).required('Customer maximum fine is required'),
    // }),
};

const Settings = () => {
    const { name } = useParams();
    const [initialValues,setInitialValues] = useState(null);
    const schema = validationSchemas[name] || yup.object().shape({});

    const {
        handleSubmit,
        formState: { errors },
        control,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: schema.getDefault(),
    });

    useEffect(() => {
        // Fetch initial data for editing if available
        const fetchInitialValues = async () => {
            try {
                const response = await apiCall("GET", "global-settings");
                console.log(response, 'Settings response');
                if (response) {
                    setInitialValues(response?.data);
                    reset(response?.data);
                } else {
                    // Set initialValues to default values if API returns empty
                    setInitialValues(schema.getDefault());
                    reset(schema.getDefault());
                }
            } catch (error) {
                console.error('Error fetching initial values:', error);
                // In case of error, reset to default values
                setInitialValues(schema.getDefault());
                reset(schema.getDefault());
            }
        };

        fetchInitialValues();
    }, []);

    const onSubmit = async (data) => {
        console.log('Form data:', data); // Debugging statement
        try { 
            const response = await apiCall("POST", 'create-global-settings', data);
            console.log(response, 'Data submitted successfully');
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    };

    return (
        <Card title={`${name?.charAt(0).toUpperCase() + name?.slice(1)} Settings`} className="p-3" style={{margin:'2%'}}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {name === 'geeks' && (
                    <div className="p-fluid grid">
                        <div className="field col-12 md:col-6">
                            <label htmlFor="geek_fine_percentage">Geek Fine Percentage</label>
                            <Controller
                                name="geek_fine_percentage"
                                control={control}
                                render={({ field }) => (
                                    <InputNumber
                                        id="geek_fine_percentage"
                                        suffix="%"
                                        min={0}
                                        max={100}
                                        value={field.value || 0}
                                        onValueChange={(e) => field.onChange(e.value)}
                                        className={errors.geek_fine_percentage ? 'p-invalid' : ''}
                                    />
                                )}
                            />
                            {errors.geek_fine_percentage && <small className="p-error">{errors.geek_fine_percentage.message}</small>}
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="geek_minimum_fine">Geek Minimum Fine</label>
                            <Controller
                                name="geek_minimum_fine"
                                control={control}
                                render={({ field }) => (
                                    <InputNumber
                                    suffix="$"
                                        id="geek_minimum_fine"
                                        value={field.value || 0}
                                        onValueChange={(e) => field.onChange(e.value)}
                                        className={errors.geek_minimum_fine ? 'p-invalid' : ''}
                                    />
                                )}
                            />
                            {errors.geek_minimum_fine && <small className="p-error">{errors.geek_minimum_fine.message}</small>}
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="geek_maximum_fine">Geek Maximum Fine</label>
                            <Controller
                                name="geek_maximum_fine"
                                control={control}
                                render={({ field }) => (
                                    <InputNumber
                                    suffix="$"
                                        id="geek_maximum_fine"
                                        value={field.value || 0}
                                        onValueChange={(e) => field.onChange(e.value)}
                                        className={errors.geek_maximum_fine ? 'p-invalid' : ''}
                                    />
                                )}
                            />
                            {errors.geek_maximum_fine && <small className="p-error">{errors.geek_maximum_fine.message}</small>}
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="geek_commission_percentage">Geek Commission Percentage</label>
                            <Controller
                                name="geek_commission_percentage"
                                control={control}
                                render={({ field }) => (
                                    <InputNumber
                                        id="geek_commission_percentage"
                                        suffix="%"
                                        min={0}
                                        max={100}
                                        value={field.value || 0}
                                        onValueChange={(e) => field.onChange(e.value)}
                                        className={errors.geek_commission_percentage ? 'p-invalid' : ''}
                                    />
                                )}
                            />
                            {errors.geek_commission_percentage && <small className="p-error">{errors.geek_commission_percentage.message}</small>}
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="geek_commission_percentage">Services Discount Percentage</label>
                            <Controller
                                name="services_discount_percentage"
                                control={control}
                                render={({ field }) => (
                                    <InputNumber
                                        id="services_discount_percentage"
                                        suffix="%"
                                        min={0}
                                        max={100}
                                        value={field.value || 0}
                                        onValueChange={(e) => field.onChange(e.value)}
                                        className={errors.services_discount_percentage ? 'p-invalid' : ''}
                                    />
                                )}
                            />
                            {errors.services_discount_percentage && <small className="p-error">{errors.services_discount_percentage.message}</small>}
                        </div>
                    </div>
                )}
                {name === 'company' && (
                    <div className="p-fluid grid">
                        <div className="field col-12 md:col-6">
                            <label htmlFor="company_fine_percentage">Company Fine Percentage</label>
                            <Controller
                                name="company_fine_percentage"
                                control={control}
                                render={({ field }) => (
                                    <InputNumber
                                        id="company_fine_percentage"
                                        suffix="%"
                                        min={0}
                                        max={100}
                                        value={field.value || 0}
                                        onValueChange={(e) => field.onChange(e.value)}
                                        className={errors.company_fine_percentage ? 'p-invalid' : ''}
                                    />
                                )}
                            />
                            {errors.company_fine_percentage && <small className="p-error">{errors.company_fine_percentage.message}</small>}
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="company_minimum_fine">Company Minimum Fine</label>
                            <Controller
                                name="company_minimum_fine"
                                control={control}
                                render={({ field }) => (
                                    <InputNumber
                                        suffix="$"
                                        id="company_minimum_fine"
                                        value={field.value || 0}
                                        onValueChange={(e) => field.onChange(e.value)}
                                        className={errors.company_minimum_fine ? 'p-invalid' : ''}
                                    />
                                )}
                            />
                            {errors.company_minimum_fine && <small className="p-error">{errors.company_minimum_fine.message}</small>}
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="company_maximum_fine">Company Maximum Fine</label>
                            <Controller
                                name="company_maximum_fine"
                                control={control}
                                render={({ field }) => (
                                    <InputNumber
                                        suffix="$"
                                        id="company_maximum_fine"
                                        value={field.value || 0}
                                        onValueChange={(e) => field.onChange(e.value)}
                                        className={errors.company_maximum_fine ? 'p-invalid' : ''}
                                    />
                                )}
                            />
                            {errors.company_maximum_fine && <small className="p-error">{errors.company_maximum_fine.message}</small>}
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="company_commission_percentage">Company Commission Percentage</label>
                            <Controller
                                name="company_commission_percentage"
                                control={control}
                                render={({ field }) => (
                                    <InputNumber
                                        id="company_commission_percentage"
                                        suffix="%"
                                        min={0}
                                        max={100}
                                        value={field.value || 0}
                                        onValueChange={(e) => field.onChange(e.value)}
                                        className={errors.company_commission_percentage ? 'p-invalid' : ''}
                                    />
                                )}
                            />
                            {errors.company_commission_percentage && <small className="p-error">{errors.company_commission_percentage.message}</small>}
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="geek_commission_percentage">Services Discount Percentage</label>
                            <Controller
                                name="services_discount_percentage"
                                control={control}
                                render={({ field }) => (
                                    <InputNumber
                                        id="services_discount_percentage"
                                        suffix="%"
                                        min={0}
                                        max={100}
                                        value={field.value || 0}
                                        onValueChange={(e) => field.onChange(e.value)}
                                        className={errors.services_discount_percentage ? 'p-invalid' : ''}
                                    />
                                )}
                            />
                            {errors.services_discount_percentage && <small className="p-error">{errors.services_discount_percentage.message}</small>}
                        </div>
                    </div>
                )}
                {name === 'customer' && (
                    <div className="p-fluid grid">
                        <div className="field col-12 md:col-6">
                            <label htmlFor="customer_fine_percentage">Customer Fine Percentage</label>
                            <Controller
                                name="customer_fine_percentage"
                                control={control}
                                render={({ field }) => (
                                    <InputNumber
                                        id="customer_fine_percentage"
                                        suffix="%"
                                        min={0}
                                        max={100}
                                        value={field.value || 0}
                                        onValueChange={(e) => field.onChange(e.value)}
                                        className={errors.customer_fine_percentage ? 'p-invalid' : ''}
                                    />
                                )}
                            />
                            {errors.customer_fine_percentage && <small className="p-error">{errors.customer_fine_percentage.message}</small>}
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="customer_minimum_fine">Customer Minimum Fine</label>
                            <Controller
                                name="customer_minimum_fine"
                                control={control}
                                render={({ field }) => (
                                    <InputNumber
                                    suffix="$"
                                        id="customer_minimum_fine"
                                        value={field.value || 0}
                                        onValueChange={(e) => field.onChange(e.value)}
                                        className={errors.customer_minimum_fine ? 'p-invalid' : ''}
                                    />
                                )}
                            />
                            {errors.customer_minimum_fine && <small className="p-error">{errors.customer_minimum_fine.message}</small>}
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="customer_maximum_fine">Customer Maximum Fine</label>
                            <Controller
                                name="customer_maximum_fine"
                                control={control}
                                render={({ field }) => (
                                    <InputNumber
                                    suffix="$"
                                        id="customer_maximum_fine"
                                        value={field.value || 0}
                                        onValueChange={(e) => field.onChange(e.value)}
                                        className={errors.customer_maximum_fine ? 'p-invalid' : ''}
                                    />
                                )}
                            />
                            {errors.customer_maximum_fine && <small className="p-error">{errors.customer_maximum_fine.message}</small>}
                        </div>
                    </div>
                )}
                <Divider />
                <Button type="submit" label="Submit" className="rounded-md bg-[#ff964b] text-[#F5F7FF] w-[12rem] p-3 mt-2 hover:bg-green-400 mb-5" />
            </form>
        </Card>
    );
};

export default Settings;
