import React,{useState,useEffect} from "react";
import Form from "../../../../components/Form";
import * as yup from "yup";
import apiCall from '../../../../api/Api'

const Add = () => {
  const [users, setUsers] = useState(null);

  useEffect(() => {
    async function fetchData(url,set) {
      try {
        const response = await apiCall('GET', url);
        let users = response?.map( user => ({ code:user.id,name:user.first_name }))
        set(users)
      } catch (error) {
        console.error("Failed to fetch from JSON:", error);
      }  
    }
    fetchData("customers",setUsers);
  },[])

  const schema = yup.object({
    message: yup.string().required("Message is required."),
    subject: yup.string().required("Subject is required."),
    seen: yup.string().required("Required."),
    // users_id:yup.string().required("User Required."),
    // seen_at:yup.string().required("Seen At Time Required.")
  });


  const fields = [
    {
      type: "text",
      name: "message",
      placeholder: "Message",
      column: 6,
    },
    {
      type: "textarea",
      name: "subject",
      placeholder: "Subject",
      column: 4,
    },
    {
      type: "toggle",
      name: "seen",
      placeholder: "Seen",
      column: 4,
      expected:'number'
      
    },
    {
      type: "dropdown",
      name: "user_id",
      placeholder: "Select User",
      column: 4,
      options:users,
    },
    // {
    //   type: "datetime",
    //   name: "seen_at",
    //   placeholder: "Seen At",
    //   column: 4,
    // },
  
  ];

  return (
    <Form title="Notification" schema={schema} fields={fields} redirectUrl='notifications' apiRoute='notifications' />
  );
};

export default Add;
