import React, { useState, useEffect } from "react";
import Company from "../../../../components/Datatable";
import { ProductService } from "../../../../utils/data";
import '../../../main.css';
import '../../../flags.css';

const List = () => {
  const [products, setProducts] = useState(null);
  useEffect(() => {
    ProductService.getProducts().then((data) => setProducts(data));
  }, []);
  
  // columns here from the API
  const column = [
    { 
      name:'code',
      title:'ID'
    },
    { 
      name:'start_time',
      title:'Start Time'
    },
    { 
      name:'end_time',
      title:'End Time'
    },
    { 
      name:'day',
      title:'Day'
    },
    { 
      name:'geek_id',
      title:'Geek'
    },
    
  ]; 

  return (
    <div>
     { products && <Company title="Schedules" data={products} column={column} addRoute='/schedule/add' detailRoute='schedule'/>}
    </div>
  );
};

export default List;
