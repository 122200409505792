import React, { useEffect, useState } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Dialog } from "primereact/dialog";
import "./DetailPage.css";
import apiCall from "../api/Api";

const DetailPage = ({ apiUrl, titleKey, subtitleKey, imageKeys = [], actions, ignoreKeys = [] }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null); // For full-view image modal

  useEffect(() => {
    getDetail(apiUrl);
  }, [apiUrl]);

  const getDetail = async (url) => {
    try {
      const response = await apiCall("GET", url);
      setData(response?.data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch details:", error);
    }
  };

  const CardHeader = () => (
    <div className="p-4 bg-orange-500 text-white rounded-t-md shadow-md">
      <h2 className="text-3xl font-bold capitalize">{data[titleKey]}</h2>
    </div>
  );

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, "0")}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}`;
  };

  const formatKey = (key) => {
    return key
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const renderContent = (key, value) => {
    // Ignore the keys you want to skip
    if (ignoreKeys.includes(key)) return null;
  
    // Handle arrays
    if (Array.isArray(value)) {
      return (
        <Accordion key={key} className="mb-3 col-span-full">
          <AccordionTab
            header={<h3 className="text-lg font-semibold break-words">{formatKey(key)}</h3>}
          >
            <div className="grid grid-cols-2 gap-4">
              {value.map((item, index) => (
                <div key={index} className="col-span-1 p-3 bg-gray-100 rounded-md shadow-sm break-words">
                  {/* Recursively check if the item is an object, otherwise just render */}
                  {typeof item === "object" && item !== null
                    ? Object.keys(item)
                        .filter((subKey) => !ignoreKeys.includes(subKey)) // Ignore unwanted keys
                        .map((subKey) => renderContent(subKey, item[subKey]))
                    : isImageUrl(item) ? (
                      <div className="flex justify-center">
                        <img
                          src={item}
                          alt={`Image ${index}`}
                          className="rounded-md shadow-md cursor-pointer"
                          onClick={() => setSelectedImage(item)}
                          style={{ width: '150px', height: '75px', objectFit: 'cover' }} // Consistent size
                        />
                      </div>
                    ) : (
                      <span className="break-words">{item}</span>
                    )}
                </div>
              ))}
            </div>
          </AccordionTab>
        </Accordion>
      );
    }
  
    // Handle objects
    else if (typeof value === "object" && value !== null) {
      return (
        <Accordion key={key} className="mb-3 col-span-full">
          <AccordionTab
            header={<h3 className="text-lg font-semibold break-words">{formatKey(key)}</h3>}
          >
            <div className="grid grid-cols-2 gap-4">
              {Object.keys(value)
                .filter((subKey) => !ignoreKeys.includes(subKey)) // Ignore unwanted keys
                .map((subKey) => (
                  <div key={subKey} className="col-span-1 p-2 bg-gray-50 rounded-md shadow-sm">
                    <strong className="text-gray-700 break-words">{formatKey(subKey)}:</strong>
                    <span className="text-gray-900 break-words">
                      {isImageUrl(value[subKey]) ? (
                        <div className="flex justify-center">
                          <img
                            src={value[subKey]}
                            alt={`Image`}
                            className="rounded-md shadow-md cursor-pointer"
                            onClick={() => setSelectedImage(value[subKey])}
                            style={{ width: '150px', height: '75px', objectFit: 'cover' }} // Consistent size
                          />
                        </div>
                      ) : subKey.toLowerCase().includes("created_at") && value[subKey] ? (
                        formatDate(value[subKey])
                      ) : (
                        value[subKey] || <span className="italic text-gray-500">N/A</span>
                      )}
                    </span>
                  </div>
                ))}
            </div>
          </AccordionTab>
        </Accordion>
      );
    }
  
    // Handle simple key-value pairs
    else {
      return (
        <div
          key={key}
          className="flex justify-between items-center mb-4 px-3 py-2 bg-gray-50 rounded-md shadow-sm"
          style={{ flexWrap: 'nowrap' }}
        >
          <strong className="text-gray-700 break-words">{formatKey(key)}:</strong>
          <span
            className="text-gray-900 inline-flex items-center justify-end"
            style={{ flexGrow: 0, whiteSpace: 'nowrap' }} // Prevent full-width stretching
          >
            {isImageUrl(value) ? (
              <div className="flex justify-center">
                <img
                  src={value}
                  alt={`Image`}
                  className="rounded-md shadow-md cursor-pointer"
                  onClick={() => setSelectedImage(value)}
                  style={{ width: '150px', height: '75px', objectFit: 'cover' }} // Consistent size
                />
              </div>
            ) : key.toLowerCase().includes("created_at") && value ? (
              formatDate(value)
            ) : (
              value || <span className="italic text-gray-500">N/A</span>
            )}
          </span>
        </div>
      );
    }
  };
  

  const isImageUrl = (value) => {
    return typeof value === "string" && value.match(/\.(jpeg|jpg|gif|png|svg)$/);
  };

  const renderImages = () => {
    return (
      <>
        <h3 className="text-2xl font-semibold italic mb-4">Images</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
          {imageKeys.map((key) => (
            Array.isArray(data[key]) ? (
              data[key].map((imageUrl, index) => (
                <div key={index} className="col-span-full">
                  <img
                    src={imageUrl}
                    alt={`Image ${index}`}
                    className="rounded-md shadow-md cursor-pointer transition-transform transform hover:scale-105"
                    onClick={() => setSelectedImage(imageUrl)}
                    style={{ width: '150px', height: '75px', objectFit: 'cover' }} // Consistent size
                  />
                </div>
              ))
            ) : data[key] ? (
              <div className="col-span-full">
                <p className="capitalize text-gray-600 mb-2">{formatKey(key)}</p>
                <img
                  src={data[key]}
                  alt={`Image`}
                  className="rounded-md shadow-md cursor-pointer transition-transform transform hover:scale-105"
                  onClick={() => setSelectedImage(data[key])}
                  style={{ width: '150px', height: '75px', objectFit: 'cover' }} // Consistent size
                />
              </div>
            ) : null
          ))}
        </div>
      </>
    );
  };

  return (
    <div className="detail-page container mx-auto p-6 bg-gray-100">
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <ProgressSpinner />
        </div>
      ) : (
        data && (
          <>
            <Card className="rounded-md shadow-lg overflow-hidden mb-6" header={CardHeader} style={{width:'100%'}}>
              <div className="p-6 bg-white">
                {/* Render images block */}
                {renderImages()}

                {/* Render simple key-value pairs */}
                <div className="">
                  {Object.keys(data).map(
                    (key) =>
                      typeof data[key] !== "object" &&
                      key !== titleKey &&
                      key !== subtitleKey &&
                      !imageKeys.includes(key) &&
                      renderContent(key, data[key])
                  )}
                </div>

                {/* Render nested objects or arrays */}
                <div>
                  {Object.keys(data).map(
                    (key) =>
                      typeof data[key] === "object" &&
                      !imageKeys.includes(key) &&
                      renderContent(key, data[key])
                  )}
                </div>

                {/* Render actions */}
                <div className="mt-6 flex justify-end space-x-4">
                  {actions &&
                    actions.map((action, index) => (
                      <Button key={index} label={action.label} icon={action.icon} onClick={action.onClick} />
                    ))}
                </div>
              </div>
            </Card>
          </>
        )
      )}

      {/* Full-screen image view modal */}
      {selectedImage && (
        <Dialog
          header="Image Preview"
          visible={!!selectedImage}
          onHide={() => setSelectedImage(null)}
          style={{ width: "50vw" }}
        >
          <img src={selectedImage} alt="Full-size preview" className="w-full h-full object-contain" />
        </Dialog>
      )}
    </div>
  );
};

export default DetailPage;
