import React from 'react';
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
// import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { profileImage } from './ContactCard';

export const JobDescriptionCard = ({jobDetailData}) => (
  <Card className="shadow-lg p-2 rounded-lg bg-white m-1" style={{maxWidth:'100%'}}>
    <div className="flex items-center mb-6">
      <img src={profileImage} alt="Company Logo" className="h-12 rounded-full mr-4" />
      <div>
        <h3 className="text-2xl font-bold">Job Title</h3>
        <p className="text-gray-500">{jobDetailData?.address?.country} • {jobDetailData?.address?.city}</p>
      </div>
    </div>

    <h4 className="text-xl font-bold mb-4">Description</h4>
    <p className="text-gray-700 mb-4">
    {jobDetailData?.description}
    </p>

    <Divider />
    {/* <h4 className="text-xl font-bold mb-4">Responsibilities:</h4>
    <ul className="list-disc ml-6 space-y-2 text-gray-700">
      <li>Meeting with the design team to discuss the needs of the company.</li>
      <li>Building and configuring Magento 1x and 2x eCommerce websites.</li>
      <li>Coding of the Magento templates.</li>
      <li>Developing Magento modules in PHP using best practices.</li>
      <li>Designing themes and interfaces.</li>
      <li>Setting performance tasks and goals.</li>
      <li>Updating website features and security patches.</li>
    </ul> */}

    {/* <Divider />
    <h4 className="text-xl font-bold mt-6 mb-4">Requirements:</h4>
    <ul className="list-disc ml-6 space-y-2 text-gray-700">
      <li>Bachelor’s degree in computer science or related field.</li>
      <li>Advanced knowledge of Magento, JavaScript, HTML, PHP, CSS, and MySQL.</li>
      <li>Experience with complete eCommerce lifecycle development.</li>
      <li>Understanding of modern UI/UX trends.</li>
      <li>Knowledge of Google Tag Manager, SEO, Google Analytics, PPC, and A/B Testing.</li>
      <li>Good working knowledge of Adobe Photoshop and Adobe Illustrator.</li>
      <li>Strong attention to detail.</li>
      <li>Ability to project-manage and work to strict deadlines.</li>
      <li>Ability to work in a team environment.</li>
    </ul> */}

    {/* <Divider /> */}
    <h4 className="text-xl font-bold mt-6 mb-4">Skills & Experience:</h4>
    <div className="flex flex-wrap gap-2">
      {
        jobDetailData?.jobSkills?.map(skill => <Tag value={skill?.name} className="bg-yellow-100 text-yellow-800" /> )
      }
      {/* <Tag value="Magento" className="bg-yellow-100 text-yellow-800" /> */}
      {/* <Tag value="Marketing" className="bg-yellow-100 text-yellow-800" /> */}
      {/* <Tag value="WordPress" className="bg-yellow-100 text-yellow-800" /> */}
      {/* <Tag value="Bootstrap" className="bg-yellow-100 text-yellow-800" /> */}
    </div>

    {/* <div className="mt-6 flex space-x-4">
      <span className="font-semibold">Share this job:</span>
      <Button label="Facebook" className="p-button-link p-button-sm" />
      <Button label="LinkedIn" className="p-button-link p-button-sm" />
      <Button label="Google+" className="p-button-link p-button-sm" />
    </div> */}
  </Card>
);

