import React, { useState,useEffect } from "react";
import Form from "../../../../components/Form";
import * as yup from "yup";
import apiCall from '../../../../api/Api'

const Add = () => {
  const [customers, setCustomers] = useState(null);
  const [categories, setCategories] = useState(null);

  useEffect(() => {
    async function fetchData(url,set) {
      try {
        const response = await apiCall(null, url);
        let users = response?.map( user => ({ code:user.id,name:user.name }))
        set(users)
      } catch (error) {
        console.error("Failed to fetch from JSON:", error);
      }  
    }
    fetchData("https://jsonplaceholder.typicode.com/users",setCustomers);
    fetchData("https://jsonplaceholder.typicode.com/users",setCategories);
  },[])

  const schema = yup.object({
    name:yup.string().required("Name is required."),
    views_count:yup.string().required("Views Count is required."),
    status:yup.string().required("Status is required."),
    min_price:yup.string().required("Min Price is required."),
    max_price:yup.string().required("Max Price is required."),
    required_time:yup.string().required("Required Time is required."),
    schedual_time:yup.string().required("Schedule Time is required."),
    customers_id:yup.array().required("Customer is required."),
    category_id:yup.string().required("Category is required."),
    descripton:yup.string().required("Description is required."),
  });

  const fields = [
    {
      type: "text",
      name: "name",
      placeholder: "Name",
      column: 4,
    },
    {
      type: "text",
      name: "views_count",
      placeholder: "Views",
      column: 4,
    },
    {
      type: "text",
      name: "status",
      placeholder: "Status",
      column: 4,
    },
    {
      type: "text",
      name: "min_price",
      placeholder: "Min Price",
      column: 4,
    },
    {
      type: "text",
      name: "max_price",
      placeholder: "Max Price",
      column: 4,
    },
    {
      type: "text",
      name: "required_time",
      placeholder: "Required Time",
      column: 4,
    },
    {
      type: "datetime",
      name: "schedual_time",
      placeholder: "Schedule Time",
      column: 4,
    },
    {
      type: "multi-select",
      name: "customers_id",
      placeholder: "Customers",
      column: 4,
      options:customers,
    },
    {
      type: "dropdown",
      name: "category_id",
      placeholder: "Categories",
      column: 4,
      options:categories,
    },
    {
      type: "textarea",
      name: "descripton",
      placeholder: "Description",
      column: 4,
    },
   
  ];

  return (
    <Form title="Job" schema={schema} fields={fields} />
  );
};

export default Add;
