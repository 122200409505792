import React,{useEffect} from 'react';
import { useForm,Controller,useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
// import { classNames } from 'primereact/utils';
import { Calendar } from "primereact/calendar";
// import { useNavigate } from "react-router-dom";
import apiCall from '../../../../api/Api'
// import { ToggleButton } from "primereact/togglebutton";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { InputSwitch } from 'primereact/inputswitch';
// import Button from 'react-bootstrap/Button';
// import 'bootstrap/dist/css/bootstrap.min.css';  

 const Form = ({schema,title,fields,getVisaType}) => {
  // const navigate = useNavigate();
  const {register,control, watch, handleSubmit,formState: { errors }} = useForm({
        defaultValues: {
          skills: [{ skill: '', price: 0 }]
        },
         resolver: yupResolver(schema) 
    });

    const { fields: skillFields , append, remove } = useFieldArray({
        control,
        name: 'skills'
      });

  const visaType = watch('visa_type');

  useEffect(() => {
    getVisaType(visaType)
  }, [visaType])
  

console.log(visaType,'visaType')
  const onSubmit = async (data) => {
    console.log("Data:",data);
    
    try {
      const response = await apiCall("POST", "customers", data);
      console.log(response, 'response');
      // navigate('/skills');
    } catch (error) {
      console.error("Failed to Add:", error);
    }
  };


  const footer = <div className="flex h-[calc(20vh-100px)] justify-center items-center outline-none gap-3">
    <Button  label="Cancel" className={`rounded-full bg-[#dc3545] text-[#F5F7FF] w-[12rem] p-3 mt-2 hover:bg-red-400 mb-5`}/>
    <Button  type='submit' label="Create" className={`rounded-full bg-[#198754] text-[#F5F7FF] w-[12rem] p-3 mt-2 hover:bg-green-400 mb-5`}/>
  </div>

const getFormErrorMessage = (name) => {
    console.log(name,'name')
//   return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
};

  const body = (value) => {
    switch (value.type) {
      case "text":
        return (
          <Col xs={12} md={4} xl={4} className="mb-5">
            <FloatLabel>
              <InputText
                {...register(value.name)}
                type={value.type}
                className={
                  errors[value.name]
                    ? "block peer rounded-[5px] w-[25rem] m-1 mt-3 border-[#C93B32] focus:outline-none focus:border-[#C93B32]  focus:ring-1 focus:ring-[#C93B32]"
                    : "block peer rounded-[5px] border-[#AEBBCD] w-[25rem] mt-3 m-1 focus:outline-none focus:ring-1"
                }
              />
              <label htmlFor="username" style={{ width: "200px" }}>
                {value.placeholder}
              </label>
            </FloatLabel>
            <span className="place-self-start text-[14px] text-[#C93B32]">
              {errors[value.name]?.message}
            </span>
          </Col>
        );

      case "dropdown":
        return (
          <Col xs={12} md={4} xl={4} className="mb-5">
            <FloatLabel className="w-full">
              <Controller
                name={value.name}
                control={control}
                // rules={{ required: 'Required.' }}
                render={({ field }) => (
                  <div style={{ width: "24.5vw", marginRight: "10px" , height:'65px'}}>
                    <Dropdown
                      id={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      options={value.options}
                      optionLabel="name"
                      focusInputRef={field.ref}
                      optionValue="code"
                      // placeholder={field.placeholder}
                      className="w-full border border-[#AEBBCD] mt-3"
                      filter
                      showClear
                    />
                    {/* {getFormErrorMessage(field.name)} */}
                  </div>
                )}
              />
              <label htmlFor={value.name}> {value.placeholder} </label>
            </FloatLabel>
          </Col>
        );

      case "multi-select":
        return (
          <div className={`col-${value.column}`}>
            <label
              htmlFor={value.name}
              className="text-md text-black dark:text-white font-bold text-left"
            >
              {value.placeholder}
            </label>
            <Controller
              name={value.name}
              control={control}
              render={({ field }) => (
                <>
                  <MultiSelect
                    id={field.name}
                    //  name="value"
                    value={field.value}
                    options={value.options}
                    onChange={(e) => field.onChange(e.value)}
                    optionLabel="name"
                    optionValue="code"
                    placeholder={value.placeholder}
                    maxSelectedLabels={3}
                    className="w-full border border-[#AEBBCD] mt-5"
                    filter
                    showClear
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>
        );

      case "image":
        return (
          // <div className={`col-${value.column}`}>
          <Col xs={12} md={4} xl={4} className="mb-5">
             {/* <FloatLabel> */}
            <label
              htmlFor={value.name}
              className="text-md text-black dark:text-white text-left"
            >
              {value.placeholder}
            </label>
            {/* <div className="card"> */}
              <FileUpload
                {...register(value.name)}
                // ref={{...register(value.name)}}
                name={value.name}
                url={"/api/upload"}
                multiple={value.multiple}
                accept="image/*"
                maxFileSize={1000000}
                emptyTemplate={
                  <p className="m-0">Drag and drop files to here to upload.</p>
                }
                className={
                  errors[value.name]
                    ? "block peer rounded-[5px] w-[25rem]  mt-5 border-[#C93B32] focus:outline-none focus:border-[#C93B32]  focus:ring-1 focus:ring-[#C93B32]"
                    : "block peer rounded-[5px] border-[#AEBBCD] w-[25rem] mt-5 focus:outline-none focus:ring-1"
                }
              />
            {/* </div> */}
            {/* </FloatLabel> */}
            <span className="place-self-start text-[14px] text-[#C93B32]">
              {errors[value.name]?.message}
            </span>
          </Col>
        );

      case "textarea":
        return (
          <div className="col-4">
            <label
              htmlFor={value.name}
              className="text-md text-black dark:text-white font-bold text-left"
            >
              {value.placeholder}
            </label>
            <textarea
              {...register(value.name)}
              rows="4"
              cols="80"
              className={
                errors[value.name]
                  ? "block peer rounded-[5px] w-[25rem]  mt-5 border-[#C93B32] focus:outline-none focus:border-[#C93B32]  focus:ring-1 focus:ring-[#C93B32]"
                  : "block peer rounded-[5px] border-[#AEBBCD] w-[25rem] mt-5 focus:outline-none focus:ring-1"
              }
            ></textarea>
            <span className="place-self-start text-[14px] text-[#C93B32]">
              {errors[value.name]?.message}
            </span>
          </div>
        );
   
      case "datetime":
        return (
          <Col
          xs={12}
          md={4}
          xl={4}
          className="w-full"
          style={{ display: "flex", flexDirection: "column", gap: 8 }}
        >
           <FloatLabel className="w-full">
           
            <Controller
              name={value.name}
              control={control}
              rules={{ required: "Date is required." }}
              render={({ field, fieldState }) => (
                <div>
                  <Calendar
                    inputId={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    dateFormat="dd/mm/yy"
                    className="rounded border-[#AEBBCD] w-[25rem] focus:outline-none focus:ring-1"
                    showTime
                    hourFormat="12"
                  />
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
             <label
              htmlFor={value.name}
              // className="text-md text-black dark:text-white text-left"
            >
              {value.placeholder}
            </label>
            </FloatLabel>
          </Col>
        );

      case "dynamicFields":
            return (
              <Col
              xs={12}
              md={4}
              xl={4}
              className="w-full"
              style={{ display: "flex", flexDirection: "column", gap: 8 }}
            >

  { skillFields.map((item, index) => (
      <div key={item.id} style={{ display:'flex',gap: 2,width:'50%'}}>
            <FloatLabel>
            <Controller
                name={`skills[${index}].skill`}
                control={control}
                // rules={{ required: 'Required.' }}
                render={({ field }) => (
                  <div style={{ width: "24.5vw", marginRight: "10px" , height:'65px'}}>
                    <Dropdown
                      id={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      options={value.options}
                      optionLabel="name"
                      focusInputRef={field.ref}
                      optionValue="code"
                      // placeholder={field.placeholder}
                      className="w-full border border-[#AEBBCD] mt-3"
                      filter
                      showClear
                    />
                    {/* {getFormErrorMessage(field.name)} */}
                  </div>
                )}
              />
                 <label htmlFor={value.name} // className="text-md text-black dark:text-white text-left" 
                 >
                  {value.placeholder}
                </label>   
                </FloatLabel>

        <Controller
            name={`skills[${index}].price`}
            control={control}
            render={({ field }) => (
              <Col xs={12} md={4} xl={4} className="mb-5">
              <FloatLabel>
                <InputText
                {...field}
                //  {...register(value.name)}
                  type='text'
                  min={0}
                  max={10}
                  className={
                    errors[value.name]
                      ? "block peer rounded-[5px] w-[25rem] m-1 mt-3 border-[#C93B32] focus:outline-none focus:border-[#C93B32]  focus:ring-1 focus:ring-[#C93B32]"
                      : "block peer rounded-[5px] border-[#AEBBCD] w-[25rem] mt-2 m-1 focus:outline-none focus:ring-1"
                  }
                />
                <label htmlFor={`skills[${index}].price`} style={{ width: "200px" }}>
                  {/* {value.placeholder} */}
                  Price
                </label>
              </FloatLabel>
              <span className="place-self-start text-[14px] text-[#C93B32]">
                {errors[value.name]?.message}
              </span>
            </Col>
            )}
          />       
          {console.log(index,'index')}

          <div className="flex h-[calc(23vh-100px)] justify-center items-center outline-none gap-3">
   {index > 0 && <Button  type='button' label="Remove" onClick={() => remove(index)} className={`rounded-full bg-[#dc8335] text-[#F5F7FF] w-[6rem] p-3 mt-2 hover:bg-red-400 mb-5`}/>}
    <Button  type='button' label="Add" onClick={() => append({ skill: '', price: 0 })} className={`rounded-full bg-[#502bd5] text-[#F5F7FF] w-[6rem] hover:bg-green-400 mb-5`}/>
  </div>

        </div>
            ))}
               
              </Col>
            );

      case "toggle":
        return (
          <Col
            xs={12}
            md={4}
            xl={4}
            className="ml-5 w-100"
            style={{ display: "flex", flexDirection: "column", gap: 8 }}
          >
            <label className="" htmlFor={value.name}>
              {value.placeholder}
            </label>
            <Controller
              name={value.name}
              control={control}
              render={({ field }) => (
                <div className="">
                  <InputSwitch
                    name={field.name}
                    // value={field.value || false}
                    checked={field.value || false}
                    onChange={(e) => field.onChange(e.value)}
                  />
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
          </Col>
        );

    case "space":
            return <div style={{width:'100vw'}}></div>


    case "heading":
        return <div style={{width:'75vw',marginBottom:'5%',marginTop:'3%', fontSize:18}}> <h4 className='font-bold h-2'>{value.placeholder}</h4> </div>
      
        default:
        return null;
    }
  };

  

  return (
    <div style={{margin:'18px'}} className='mt-5'>
      <form onSubmit={handleSubmit(onSubmit)}>
         <Card title={`Add ${title}`} footer={footer} style={{maxWidth:'100%'}}>
        <div className="flex flex-wrap">
            {
                fields?.map(field => 
                    <React.Fragment key={field.name}>
                      <Row>
                         {body(field)}
                      </Row>
                    </React.Fragment>
                         )
            }
        </div>
        </Card>
      </form>
    </div>
  );
}


export default Form;