import React, { useState,useEffect } from "react";
import Form from "../../../../components/Form";
import * as yup from "yup";
import apiCall from '../../../../api/Api'

const Add = () => {
  const [geeks, setGeeks] = useState(null);
  const [jobs, setJobs] = useState(null);

  useEffect(() => {
    async function fetchData(url,set) {
      try {
        const response = await apiCall(null, url);
        let users = response?.map( user => ({ code:user.id,name:user.name }))
        set(users)
      } catch (error) {
        console.error("Failed to fetch from JSON:", error);
      }  
    }
    fetchData("https://jsonplaceholder.typicode.com/users",setGeeks);
    fetchData("https://jsonplaceholder.typicode.com/users",setJobs);
  },[])

  const schema = yup.object({
    geeks_id: yup.string().required("Geek ID is required."),
    jobs_id: yup.string().required("Job ID is required."),
    status: yup.string().required("Status is required."),
    start_time: yup.string().required("Start Time is required."),
    end_time: yup.string().required("End Time is required."),
    total_bill: yup.string().required("Total Bill is required."),
    total_paid: yup.string().required("Total Paid is required."),
  });


  const fields = [
    {
      type: "dropdown",
      name: "geeks_id",
      placeholder: "Select Geek",
      column: 4,
      options:geeks
    },
    {
      type: "dropdown",
      name: "jobs_id",
      placeholder: "Select Job",
      column: 4,
      options:jobs
    },
    {
      type: "datetime",
      name: "start_time",
      placeholder: "Start Time",
      column: 4,
    },
    {
      type: "datetime",
      name: "end_time",
      placeholder: "End Time",
      column: 4,
    },
    {
      type: "text",
      name: "total_bill",
      placeholder: "Total Bill",
      column: 4,
    },
    {
      type: "text",
      name: "total_paid",
      placeholder: "Total Paid",
      column: 4,
    },
    {
      type: "toggle",
      name: "status",
      placeholder: "Status",
      column: 4,
    },
  
  ];

  return (
    <Form title="Geek Job" schema={schema} fields={fields} />
  );
};

export default Add;
