import React, {  } from "react";
import Form from "../../../../components/Form";
import * as yup from "yup";

const Add = () => {

  const schema = yup.object({
    salutation: yup.string().required("Salutation is required."),
    first_name: yup.string().required("First Name is required."),
    middle_name: yup.string().required("Middle Name is required."),
    last_name: yup.string().required("Last Name is required."),
    mobile: yup.string().required("Mobile is required."),
    gender: yup.string().required("Gender is required."),
    phone: yup.string().required("Phone is required."),
    dob:yup.string().required("Date Of Birth is required."),
  });

  const fields = [
    {
      type: "text",
      name: "salutation",
      placeholder: "Salutation",
      column: 4,
    }, 
    {
      type: "text",
      name: "first_name",
      placeholder: "First Name",
      column: 4,
    },
    {
      type: "text",
      name: "middle_name",
      placeholder: "MIddle Name",
      column: 4,
    },
    {
      type: "text",
      name: "last_name",
      placeholder: "Last Name",
      column: 4,
    },
    {
      type: "text",
      name: "mobile",
      placeholder: "Mobile",
      column: 4,
    },
    {
      type: "text",
      name: "gender",
      placeholder: "Gender",
      column: 4,
    },
    {
      type: "text",
      name: "phone",
      placeholder: "Select Phone",
      column: 4,
    },
    {
      type: "text",
      name: "dob",
      placeholder: "Select Date of birth",
      column: 4,
    },
    
  ];

  return (
    <Form title="People" schema={schema} fields={fields} />
  );
};

export default Add;
