import React, { useState, useEffect } from "react";
import Company from "../../../../components/Datatable";
// import { ProductService } from "../../../../utils/data";
import '../../../main.css';
import Loader from "../../../../components/Loader";
// import '../../../flags.css';
import apiCall from "../../../../api/Api";

const List = () => {
  const [products, setProducts] = useState(null); 
  const [loader, setloader] = useState(false); 
  const [page, setPage] = useState(1); 
  const [perPage, setPerPage] = useState(10); 
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    const getJobs = async () => {
      try {
        setloader(true)
        const response = await apiCall("GET", `jobs?page=${page}&perPage=${perPage}`);
        setProducts(response?.data)
        setTotalRecords(response.meta?.total); // Assuming your API returns total record count
        setloader(false)
      } catch (error) {
        console.error("Failed to Add:", error);
      }
    }
    getJobs()
    
  }, [page, perPage]);
  
  // columns here from the API
  const column = [
    { 
      name:'id',
      title:'ID',
      sortable:true

    },
    { 
      name:'customer.first_name',
      title:'Customer Name',
      sortable:true

    },
    // { 
    //   name:'address',
    //   title:'Job Address',
    //   sortable:true,
    //   type:'formatter',
    //   keys:['adrr_1','adrr_2','city','country']
    
    // },
    // { 
    //   name:'name',
    //   title:'Service Name',
    //   sortable:true
    // },
    { 
      name:'description',
      title:'Description',
      sortable:true

    },
    { 
      name:'jobSkills',
      title:'Job Skills',
      sortable:true,
      type:'formatter',
      keys:['name']

    },
    { 
      name:'booking_option',  
      title:'Booking',
      sortable:true
    },
    { 
      name:'status',  
      title:'Status',
      showFilter:true,
      type:'status',
      showFilterElement:true,
      sortable:false,
      filterableStatuses:['Active', 'InActive', 'Pending','Suspended']

    },
  ]; 
// Handle page change
const handlePageChange = (newPage, newRowsPerPage) => {
  setPage(newPage);
  setPerPage(newRowsPerPage);
 };

  if(loader === true) return <Loader />
  return (
    <div>
        <Company
          title="Jobs"
          data={products}
          column={column}
          // addRoute="/job/add"
          detailRoute={{route: 'jobs',apiUrl:'jobs'}} showDetailsRoute={true}
          // detailRoute="jobs"
          toggleFilters={['Active', 'InActive', 'Pending','Suspended','completed']}
          totalRecords={totalRecords}
          perPage={perPage}
          page={page}
          onPageChange={handlePageChange}
          loading={loader}
        />
    </div>
  );
};

export default List;
