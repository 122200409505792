import React from 'react';
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import ContactDetail from './ContactDetail';
import DetailRow from './DetailRow';
import { Tag } from 'primereact/tag';
import { useNavigate } from 'react-router-dom';

export const profileImage = "https://static.vecteezy.com/system/resources/thumbnails/036/594/092/small_2x/man-empty-avatar-photo-placeholder-for-social-networks-resumes-forums-and-dating-sites-male-and-female-no-photo-images-for-unfilled-user-profile-free-vector.jpg";
export const ContactCard = ({jobDetailData}) => {

const navigate = useNavigate()

return  <Card className="shadow-lg rounded-lg bg-white">
    <div className="flex flex-col items-center">
      <img src={profileImage} alt="Company Logo" className="h-12 rounded-full" />
      <h2 className="text-lg font-bold">{` ${jobDetailData?.customer?.first_name} ${jobDetailData?.customer?.last_name} `} </h2>
      <DetailRow
        label="Status:"
        value={<Tag value={jobDetailData?.customer?.status} className="bg-blue-100 text-blue-800 ml-3 rounded-md capitalize" />}
      />
    </div>
    <Divider />
    <div className="text-sm">
      <ContactDetail icon="pi pi-phone" label="Phone" value="+589 560 56555" />
      <ContactDetail icon="pi pi-envelope" label="Email" value={jobDetailData?.customer?.email}/>
      {/* <ContactDetail icon="pi pi-globe" label="Website" value="www.themesbrand.com" /> */}
      <ContactDetail icon="pi pi-map-marker" label="Location" value="Oakridge Lane Richardson." />
    </div>
    <div className="mt-1 text-center"> 
      <Button label="View Profile" icon="pi pi-eye" className="p-button-outlined p-button-info" type='button' onClick={() => navigate(`/customers/${jobDetailData?.customer?.id}/detail`,{state:{endpoint : {apiUrl :'customers'}}})}/>
    </div>
  </Card>
};

