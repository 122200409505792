import React, { useState,useEffect } from "react";
import Form from "../../../../components/Form";
import * as yup from "yup";
import apiCall from '../../../../api/Api'

const Add = () => {

  const [geeks, setGeeks] = useState(null);

  useEffect(() => {
    async function fetchData(url,set) {
      try {
        const response = await apiCall(null, url);
        let users = response?.map( user => ({ code:user.id,name:user.name }))
        set(users)
      } catch (error) {
        console.error("Failed to fetch from JSON:", error);
      }  
    }
    fetchData("https://jsonplaceholder.typicode.com/users",setGeeks);
  },[])

  const schema = yup.object({
    start_time: yup.string().required("Field is required."),
    end_time: yup.string().required("Field is required."),
    day: yup.string().required("Field is required."),
    geek_id: yup.string().required("Field is required.")
  });

  const fields = [
    {
      type: "datetime",
      name: "start_time",
      placeholder: "Start Time",
      column: 4,
    }, 
    {
      type: "datetime",
      name: "end_time",
      placeholder: "End Time",
      column: 4,
    },
    {
      type: "datetime",
      name: "day",
      placeholder: "Day",
      column: 4,
    },
    {
      type: "dropdown",
      name: "geek_id",
      placeholder: "Select Geek",
      column: 4,
      options:geeks,
    },
  ];

  return (
    <Form title="Schedule" schema={schema} fields={fields} />
  );
};

export default Add;
