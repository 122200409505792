import React, { useState, useEffect } from "react";
import Company from "../../../../components/Datatable";
import { ProductService } from "../../../../utils/data";
import '../../../main.css';
import '../../../flags.css';

const List = () => {
  const [products, setProducts] = useState(null);
  useEffect(() => {
    ProductService.getProducts().then((data) => setProducts(data));
  }, []);
  
  // columns here from the API
  const column = [
    { 
      name:'code',
      title:'ID'
    },
    { 
      name:'salutation',
      title:'Salutation'
    },
    { 
      name:'first_name',
      title:'First Name'
    },
    { 
      name:'status',
      title:'Status'
    },
    { 
      name:'middle_name',
      title:'Middle Name'
    },
    { 
      name:'last_name',
      title:'Last Name'
    },
    { 
      name:'mobile',
      title:'Mobile'
    },
    { 
      name:'gender',
      title:'Gender'
    },
    { 
      name:'phone',
      title:'Phone'
    },
    { 
      name:'dob',
      title:'DOB'
    },
    
  ]; 

      // Loader here
  if (products === null) return <p>Loading!!</p>;
  return (
    <div>
      <Company title="People" data={products} column={column} addRoute='/people/add' detailRoute='peoples'/>
    </div>
  );
};

export default List;
