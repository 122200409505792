import React, { useEffect,useState } from 'react';
import { Overview } from './Overview';
import { ContactCard } from './ContactCard';
import { JobDescriptionCard } from './JobDescriptionCard';
import { useParams } from 'react-router-dom';
import apiCall from '../../api/Api';
import Loader from "../../components/Loader";

const DetailsSection = () => {
   const { id }  = useParams()
   const [jobDetailData, setData] = useState(null); 

   useEffect(() => {
     const getJobData = async () => {
       try {
         const response = await apiCall("GET", `jobs/${id}`);
         console.log(response, 'detail response');
         setData(response)
       } catch (error) {
         console.error("Failed to Add:", error);
       }
     }
     getJobData()
     
   }, [id]);

   if(jobDetailData === null ) return <Loader/>
   
  return (
    <div className="flex flex-col lg:flex-row p-4 lg:p-8">
      {/* Overview and Contact Section */}
      <div className="lg:w-1/3 flex flex-col space-y-4 mb-4">
        <Overview  jobDetailData={jobDetailData}/>
        <ContactCard jobDetailData={jobDetailData}/>
      </div>

      {/* Job Description Section */}
      <div className="lg:w-2/3">
        <JobDescriptionCard jobDetailData={jobDetailData}/>
      </div>
    </div>
  );
};

export default DetailsSection;
