import React, { useState, useEffect } from "react";
import Company from "../../../../components/Datatable";
// import { ProductService } from "../../../../utils/data";
import '../../../main.css';
import '../../../flags.css';
import apiCall from "../../../../api/Api";
import Loader from '../../../../components/Loader'

const List = () => {
  const [products, setProducts] = useState(null);
  const [loader, setloader] = useState(false); 
  const [page, setPage] = useState(1); 
  const [perPage, setPerPage] = useState(10); 
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    getGeeks()
  }, [page, perPage]);
  const getGeeks = async () => {
    try {
      setloader(true)
      const response = await apiCall("GET", `geeks?page=${page}&perPage=${perPage}`);
      setProducts(response?.data)
      setTotalRecords(response.meta?.total); // Assuming your API returns total record count
      setloader(false)
      // navigate('/companies');
    } catch (error) {
      console.error("Failed to Add:", error);
    }
  }
  // columns here from the API
  const column = [
    { 
      name:'id',
      title:'ID',
      sortable:true
    },
    { 
      name:'first_name',
      title:'First Name',
      sortable:true
    },
    { 
      name:'last_name',
      title:'Last Name',
      sortable:true
    },
    { 
      name:'email',
      title:'Email',
      sortable:true
    },
    { 
      name:'mobile',
      title:'Mobile',
      sortable:true
    },
    { 
      name:'status',
      title:'Status',
      type:'status',
      kind:'easy-edit',
      postUrl:'/geek-status',
      dropdownOptions:[
        { label: "Pending", value: "pending" },
        { label: "Active", value: "active" },
        { label: "InActive", value: "inactive" },
        { label: "Suspended", value: "suspended" },
      ]
    },
    { 
      name:'is_online',
      title:'Is Online',
      type:'status'
    },
  ]; 

    // Handle page change
    const handlePageChange = (newPage, newRowsPerPage) => {
      setPage(newPage);
      setPerPage(newRowsPerPage);
    };

      // Loader here
      if (loader === true) return <Loader/>;
  return (
    <div>
      <Company 
         title="Geeks" 
         data={products} 
         column={column} 
         addRoute='/geek/add' 
         showFurtherDetailButton={false} 
         showReviewButton={false}
         detailRoute={{route: 'geeks',apiUrl:'geeks'}} 
         showDetailsRoute={true}
         fetchDataAgain={getGeeks}
         totalRecords={totalRecords}
         perPage={perPage}
         page={page}
         onPageChange={handlePageChange}
         loading={loader}
      />
    </div>
  );
};

export default List;
