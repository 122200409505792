import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

const Loader = ()  => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: 'var(--surface-ground)' // Optional: match the background color
          }}>
            <div className="card">
              <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
            </div>
          </div>
    );
}

export default Loader;