// DetailPage.js

import React from 'react';
import { useParams,useLocation } from 'react-router-dom';
import { DetailPage } from '../../../../components';

const Detail = () => {
  const { id } = useParams();
  // const params = useParams();
  const location = useLocation();
  const { endpoint } = location.state;
  return (
<DetailPage
  apiUrl={`${endpoint.apiUrl}/${id}`}
  titleKey="name"
  subtitleKey="name"
  imageKeys={['insurance', 'license', 'certificate']} // Pass keys with multiple images
  ignoreKeys={['created_at', 'updated_at']} // Fields to ignore
  // actions={actions}
/>

  );
};

export default Detail;
