import React, { useState, useEffect } from "react";
import Company from "../../../../components/Datatable";
// import { ProductService } from "../../../../utils/data";
import '../../../main.css';
import '../../../flags.css';
import Loader from "../../../../components/Loader";
import apiCall from "../../../../api/Api";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

const List = () => {
  const [products, setProducts] = useState(null); 
  const [loader, setloader] = useState(false); 
  const [page, setPage] = useState(1); 
  const [perPage, setPerPage] = useState(10); 
  const [totalRecords, setTotalRecords] = useState(0);

  const navigate =  useNavigate()
  useEffect(() => {
    const getWallets = async () => {
      try {
        const response = await apiCall("GET", `wallet?page=${page}&perPage=${perPage}`);
        setProducts(response?.data)
        setTotalRecords(response.meta?.total); // Assuming your API returns total record count
        setloader(false)
      } catch (error) {
        console.error("Failed to Add:", error);
      }
    }
    getWallets()
    // ProductService.getProducts().then((data) => setProducts(data));
  }, [page, perPage]);

  
  const column = [
    { 
      name:'id',
      title:'ID',
      sortable:true
    },
    { 
      name:'email',
      title:'Email',
      sortable:true
    },
    { 
      name:'full_name',
      title:'Name',
      sortable:true
    },
 
    { 
      name:'current_balance',
      title:'Current Balance',
      sortable:true
    },
    { 
      name:'balance_hold',
      title:'Balance Hold',
      sortable:true
    },
    { 
      name:'last_transaction_date',
      title:'Last Transaction',
      sortable:true
    },

  ]; 

  const handleWalletTransactions = (rowData)  => {
    console.log('clicked',rowData)
    navigate(`/wallet-transactions/${rowData?.id}`,{state:{title:rowData?.full_name || rowData?.name}})
  }


  const walletTransactions = (rowData) =>  <Button icon="pi pi-wallet" rounded outlined className="m-1" onClick={() => handleWalletTransactions(rowData)} />


  // Handle page change
  const handlePageChange = (newPage, newRowsPerPage) => {
    setPage(newPage);
    setPerPage(newRowsPerPage);
   };

      // Loader here
  if (products === null) return <Loader/>;
  return (
    <div>
      <Company 
      title="Wallets" 
      data={products} 
      column={column} 
      detailRoute='wallets' 
      showAddtionalButton=
      {walletTransactions}
      totalRecords={totalRecords}
      perPage={perPage}
      page={page}
      onPageChange={handlePageChange}
      loading={loader}
      />
    </div>
  );
};

export default List;
