// DetailPage.js

import React from 'react';
import { useParams,useLocation } from 'react-router-dom';
import { DetailPage } from '../../../../components';

const Detail = () => {
  const { id } = useParams();
  // const params = useParams();
  const location = useLocation();
  const { endpoint } = location.state;
  console.log(endpoint,'ss')

  return (
  <DetailPage
    apiUrl={`${endpoint?.apiUrl}/${id}`}
    titleKey={endpoint}
    subtitleKey="first_name"
    imageKeys={['profile_image', 'eid_front_side_image', 'eid_back_side_image','work_license_front_image','work_license_back_image']} // Pass keys with multiple images
    ignoreKeys={['created_at', 'updated_at','skill','addressable_type','addressable_id', 'deleted_at']} // Fields to ignore
    // actions={actions}
/>
  );
};

export default Detail;
