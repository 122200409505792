import React, { useState,useEffect } from "react";
import Form from "../../../../components/Form";
import * as yup from "yup";
import apiCall from "../../../../api/Api";

const Add = () => {
  const [permissions, setPermissions] = useState(null);
 

  useEffect(() => {
    const getSkills = async () => {
      try {
        const response = await apiCall("GET", "role-create");
        let skl = response?.data?.map(sk => (
          {
            name: sk.name,
            code : sk.id
          }
      ))
      setPermissions(skl)
      } catch (error) {
        console.error("Failed to Add:", error);
      }
    }

    getSkills()
    
  }, []);
  


  const schema = yup.object({
    // target_radius: yup.string().required("Raduis is required."),
    // status: yup.string().required("Status is required."),
    // user_id: yup.string().required("Trade lisence is required."),
    // people_id: yup.string().required("Trade lisence is required."),
  });



  const fields = [
    {
      type: "text",
      name: "name",
      placeholder: "Role Name",
      column: 4,
    },
    {
      type: "multi-select",
      name: "permission",
      placeholder: "Select Permissions",
      column: 4,
      options:permissions,
    },
   
   
  ];
  // if(peopleOption === null && usersOption === null) return <p>Loading!!!!</p>
  // else  return (
  return  <Form title="Role" redirectUrl='roles' schema={schema} fields={fields} apiRoute='roles' />
  // );
};

export default Add;
