import React,{useRef} from "react";
import { useForm , Controller} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link } from "react-router-dom";
import regex from '../../../../../utils/regex';
// import notRed from "../../assets/not-red.svg";
// import checkGreen from "../../assets/check-green.svg";
import { Stepper } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';
import { Button } from 'primereact/button';
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { InputOtp } from 'primereact/inputotp';
import { Password } from 'primereact/password';

// import apiCall from "../../../../../api/Api";

const schema = yup.object({
    // name: yup.string()
    //     .required("The name field is required.")
    //     .min(3, "The name field must contain at least 3 characters."),
    email: yup.string()
        .required("Email field is required.")
        .email("Please enter a valid e-mail."),
    phone: yup.string()
        .required("Phone field is required."),
    password: yup.string()
        .required("Password field is required.")
        .min(8, "Enter a password of at least 8 characters.")
        .matches(regex.number, "Enter at least 1 number.")
        .matches(regex.lowerCase, "Enter at least 1 lowercase character.")
        .matches(regex.upperCase, "Enter at least 1 uppercase character.")
        .matches(regex.specialCharacter, "Enter at least 1 special character."),
    password_confirmation: yup.string()
        .required("The confirm password field is required.")
        .oneOf([yup.ref("password")], "Passwords are not the same."),
    sms_otp: yup.number()
        .required("The SMS OTP field is required.")
        .min(4, "The OTP field must contain at least 4 characters."),
    email_otp: yup.number()
        .required("The Email OTP field is required.")
        .min(4, "The OTP field must contain at least 4 characters."),
    
})

export default function SignUp() {
    
    // const navigate = useNavigate()
    const { register,setValue,control, handleSubmit : onSubmit,formState: { errors }} = useForm({resolver: yupResolver(schema)});
    const stepperRef = useRef(null);
    const handleSubmit = async (data) => {
        const signUpData = data;
        console.log(signUpData,'dataaa sign up')
        // const userData = {
        //     // name: 'John Doe',
        //     // email: 'john@example.com',
        //   };
        // try {
        //   const response = await apiCall("post", "login", userData);
        //   console.log(response,'response')
        //   // console.log("Registration Successfully:", signUpData);
        //   // navigate('/')
        //   // window.location.reload()
        
        // } catch (error) {
        //   console.error("Failed to create user:", error);
        // }
    }

    // const name = register("name")

    // const getEmailOtp = async () => {
    //   let {email,phone} = getValues()
    //   const userData = {
    //     email,
    //     mobile:phone,
    //     role:'admin',
    //     fcm_token:'cTNacv7zRjOPccbnMZr7vo:APA91bHjhiZLqQwGDhDOBJDT0p8YPaWMo0jNwe7d6zXJ4N0Pe6GvweF60GJ31hwRT9iBUSMV7BP1asXWQAaDsZTZ0uLSL0aiO9Quu8CkgQVeAmlr5XZTXC_Bsk49HYuG_fgamgoSzvDH',
    //     platform_type:'web'
    //   }
    //   if(email && phone){
    //     try {
    //       const response = await apiCall("POST", "register", userData);
    //       console.log(response,'aa')
    //       if(response){
    //         stepperRef.current.nextCallback()
    //       }
        
    //     } catch (error) {
    //       console.error("Failed to create user:", error);
    //     }
    //   }
     
    // }

    // const getPhoneOtp = async () => {
    //   let {email,email_otp} = getValues()
    //   console.log(email,email_otp)
    //   if(email && email_otp){
    //     try {
    //       const response = await apiCall("POST", "verify-otp", userData);
    //       console.log(response,'aa')
    //       if(response){
    //         stepperRef.current.nextCallback()
    //       }
        
    //     } catch (error) {
    //       console.error("Failed to create user:", error);
    //     }
    //   }
     
    // }

    // const showPassowrdFields = async () => {
    //   let {email,sms_otp} = getValues()
    //   console.log(email,sms_otp)
    //   if(email && email_otp){
    //     try {
    //       const response = await apiCall("POST", "verify-mobile-otp", userData);
    //       console.log(response,'aa')
    //       if(response){
    //         stepperRef.current.nextCallback()
    //       }
    //     } catch (error) {
    //       console.error("Failed to create user:", error);
    //     }
    //   }
     
    // }

     const sendValues = () => {
       console.log('all values')
     }
    

    
    return (
      <div className="flex flex-col items-center">
        <form
          onSubmit={onSubmit(handleSubmit)}
          className="flex flex-col items-center outline-none"
        >
          <p className="place-self-start font-semibold text-base text-[#5473E3]">
            Register to the system
          </p>
          <div className="card flex justify-content-center">
            <Stepper ref={stepperRef} style={{ flexBasis: "50rem" }}>
        
              <StepperPanel header="Sign Up Information">
                <div className="flex flex-column h-15rem align-items-center">
                  <div className="p-5 border-2 border-dashed surface-border border-round surface-ground flex-auto justify-content-center align-items-center font-medium">
                    <FloatLabel>
                      <label htmlFor="email" className="block">
                        Email
                      </label>
                      <InputText
                        {...register("email")}
                        name="email"
                        type="email"
                        className={
                          errors.email
                            ? "block peer rounded-[5px] w-[25rem]  mt-5 border-[#C93B32] focus:outline-none focus:border-[#C93B32]  focus:ring-1 focus:ring-[#C93B32]"
                            : "block peer rounded-[5px] border-[#AEBBCD] w-[30rem] mt-5 focus:outline-none focus:ring-1"
                        }
                      />
                      <span className="place-self-start text-[14px] text-[#C93B32]">
                        {errors.email?.message}
                      </span>
                    </FloatLabel>
                    <FloatLabel>
                      <label htmlFor="phone" className="block">
                        Phone
                      </label>
                      <InputMask
                      {...register("phone")}
                        name="phone"
                        id="phone"
                        mask="(99) 999-999-9999"
                        placeholder="(999) 999-9999"
                        className="block peer rounded-[5px] border-[#AEBBCD] w-[30rem] mt-5 focus:outline-none focus:ring-1"
                      ></InputMask>
                       <span className="place-self-start text-[14px] text-[#C93B32]">
                        {errors.phone?.message}
                      </span>
                    </FloatLabel>
                  </div>
                </div>
                <div className="flex pt-4 justify-content-end">
                  <Button
                    label="Send Codes"
                    icon="pi pi-arrow-right"
                    iconPos="right"
                    // onClick={() => getEmailOtp()}
                  />
                </div>
              </StepperPanel>
             
              <StepperPanel header="Email OTP">
              <div className="flex flex-column h-20rem">
                  <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto justify-content-center align-items-center font-medium">
                    <div className="flex flex-column align-items-center">
                      <p className="font-bold text-xl mb-2">
                        Authenticate Your Account
                      </p>
                      <p className="text-color-secondary block mb-5">
                        Please enter the code sent to your email.
                      </p>
                      <label htmlFor="email_otp" className="block m-2">
                        Email OTP
                      </label>
                      <InputOtp
                        name="email_otp"
                        {...register("email_otp")}
                        // value={token}
                        onChange={(e) => setValue('email_otp',e.value,{
                          shouldTouch:true,
                          shouldDirty:true,
                          shouldValidate:true
                        })}
                        integerOnly
                        className={
                          errors.email_otp
                            ? "block peer rounded-[5px] w-[25rem]  mt-5 border-[#C93B32] focus:outline-none focus:border-[#C93B32]  focus:ring-1 focus:ring-[#C93B32]"
                            : "block peer rounded-[5px] border-[#AEBBCD] w-[25rem] mt-5 focus:outline-none focus:ring-1"
                        }
                      />
                      <span className="place-self-center text-[14px] text-[#C93B32] mt-2">
                        {errors.email_otp?.message}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex pt-4 justify-content-between">
                  <Button
                    label="Back"
                    severity="secondary"
                    icon="pi pi-arrow-left"
                    onClick={() => stepperRef.current.prevCallback()}
                  />
                  <Button
                    label="Verify Code"
                    icon="pi pi-arrow-right"
                    iconPos="right"
                    // onClick={() => getPhoneOtp()}
                  />
                </div>
              </StepperPanel>

              <StepperPanel header="SMS OTP">
                <div className="flex flex-column h-20rem">
                  <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto justify-content-center align-items-center font-medium">
                    <div className="flex flex-column align-items-center">
                      <p className="font-bold text-xl mb-2">
                        Authenticate Your Account
                      </p>
                      <p className="text-color-secondary block mb-5">
                        Please enter the code sent to your phone.
                      </p>

                      <label htmlFor="sms_otp" className="block m-2">
                        SMS OTP
                      </label>
                      <InputOtp
                        name="sms_otp"
                        {...register("sms_otp")}
                        // value={token}
                        // onChange={(e) => setTokens(e.value)}
                        onChange={(e) => setValue('sms_otp',e.value,{
                          shouldTouch:true,
                          shouldDirty:true,
                          shouldValidate:true
                        })}
                        integerOnly
                        className={
                          errors.sms_otp
                            ? "block peer rounded-[5px] w-[25rem]  mt-5 border-[#C93B32] focus:outline-none focus:border-[#C93B32]  focus:ring-1 focus:ring-[#C93B32]"
                            : "block peer rounded-[5px] border-[#AEBBCD] w-[25rem] mt-5 focus:outline-none focus:ring-1"
                        }
                      />
                      <span className="place-self-center text-[14px] text-[#C93B32] mt-2">
                        {errors.sms_otp?.message}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex pt-4 justify-content-between">
                  <Button
                    label="Back"
                    severity="secondary"
                    icon="pi pi-arrow-left"
                    // onClick={() => showPassowrdFields()}
                  />
                  <Button
                    label="Verify Code"
                    icon="pi pi-arrow-right"
                    iconPos="right"
                    onClick={() => stepperRef.current.nextCallback()}
                  />
                </div>
              </StepperPanel>

              <StepperPanel header="Create Password">
                  <div className="flex flex-column h-20rem ">
                    <div className="p-5 border-2 border-dashed surface-border border-round surface-ground flex-auto justify-content-center align-items-center font-medium">
                    <div  style={{margin:'5%'}}>
                      <FloatLabel>      
                        <Controller
                          name="password"
                          control={control}
                          render={({ field }) => (
                            <>
                            <Password 
                            {...field}
                            inputId="password" 
                            // required
                            name='password'
                            toggleMask
                            inputStyle={{
                              width:'100%'
                            }}
                            // value={value} 
                            onChange={(e) => setValue('password',e.target.value)} 
                            className={
                              errors.password
                                ? "block peer rounded-[5px] w-[25rem]  mt-5 border-[#C93B32] focus:outline-none focus:border-[#C93B32]  focus:ring-1 focus:ring-[#C93B32]"
                                : "block peer rounded-[5px] border-[#AEBBCD] focus:outline-none focus:ring-1"
                            }
                            />
                            <label htmlFor="password" className="block mb-2">
                            Password
                          </label>
                          </>
                          )}
                        />
                        <span className="place-self-start text-[14px] text-[#C93B32]">
                          {errors.password?.message}
                        </span>
                      </FloatLabel>
                      </div>
                      <div style={{margin:'5%'}}>
                      <FloatLabel>
                      
                        <Controller
                          name="password_confirmation"
                          control={control}
                          render={({ field }) => (
                            <>
                            <Password 
                            {...field}
                            inputId="password_confirmation" 
                            // required
                            name='password_confirmation'
                            toggleMask
                            inputStyle={{
                              width:'100%'
                            }}
                            // value={value} 
                            onChange={(e) => setValue('password_confirmation',e.target.value)} 
                            className={
                              errors.password
                                ? "block peer rounded-[5px] w-[25rem]  mt-5 border-[#C93B32] focus:outline-none focus:border-[#C93B32]  focus:ring-1 focus:ring-[#C93B32]"
                                : "block peer rounded-[5px] border-[#AEBBCD] focus:outline-none focus:ring-1"
                            }
                            />
                            <label htmlFor="password_confirmation" className="block mb-2">
                            Confirm Password
                          </label>
                          </>
                          )}
                        />
                        <span className="place-self-start text-[14px] text-[#C93B32]">
                          {errors.password_confirmation?.message}
                        </span>
                      </FloatLabel>
                      </div>
                    </div>
                    </div>
                    <div className="flex pt-4 justify-content-between">
                                <Button
                                  label="Back"
                                  severity="secondary"
                                  icon="pi pi-arrow-left"
                                  onClick={() => sendValues()}
                                />
                                <Button
                                  label="Verify Code"
                                  icon="pi pi-arrow-right"
                                  iconPos="right"
                                  onClick={() => stepperRef.current.nextCallback()}
                                />
                      </div>
              </StepperPanel>
            </Stepper>
          </div>
          <button
            type="submit"
            className={`rounded-full bg-[#3D5FD9] text-[#F5F7FF] w-[25rem] p-3 mt-5 hover:bg-[#2347C5] mb-5`}
            // onClick={onHandleSubmit}
          >
            SIGN UP
          </button>

          <Link to="/" className="hover:text-[#2347C5] hover:underline">
            <p className="text-[#5473E3] mb-5">
              Already have an account ? Sign in
            </p>
          </Link>
        </form>
      </div>
    );
}