import React,{useState} from 'react';
import { Link, NavLink } from 'react-router-dom';
import { SiShopware } from 'react-icons/si';
import { MdOutlineCancel } from 'react-icons/md';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
// import { Dropdown } from 'primereact/dropdown';
import { getlinks } from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';

const Sidebar = ({currentUser}) => {
  const { currentColor, activeMenu, setActiveMenu, screenSize } = useStateContext();
  const [openDropdown, setOpenDropdown] = useState(null);
  // const [dropdownValue, setDropdownValue] = useState(null);

  const handleDropdownClick = (name) => {
    setOpenDropdown((prev) => (prev === name ? null : name));
  };
  const handleCloseSideBar = () => {
    if (activeMenu !== undefined && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const activeLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2';
  const normalLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2';
 

  // const handleDropdownChange = (e) => {
  //   setDropdownValue(e.value);
  //   handleCloseSideBar();
  // };

  function cleanString(input) {
    return input.replace(/[_\W]+/g, ' ').trim();
  }
  


  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <Link to="/" onClick={handleCloseSideBar} className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900">
              <SiShopware /> <span>Geek Redar</span>
            </Link>
            <TooltipComponent content="Menu" position="BottomCenter">
              <button
                type="button"
                onClick={() => setActiveMenu(!activeMenu)}
                style={{ color: currentColor }}
                className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
              >
                <MdOutlineCancel />
              </button>
            </TooltipComponent>
          </div>
          {/* <div className="mt-10 ">
            {links.map((item) => (
              <div key={item.title}>
                <p className="text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase">
                  {item.title}
                </p>
                {item.links.map((link) => (
                  <NavLink
                    to={`/${link.name}`}
                    key={link.name}
                    onClick={handleCloseSideBar}
                    style={({ isActive }) => ({
                      backgroundColor: isActive ? currentColor : '',
                    })}
                    className={({ isActive }) => (isActive ? activeLink : normalLink)}
                  >
                    {link.icon}
                    <span className="capitalize ">{link.name}</span>
                  </NavLink>
                ))}
              </div>
            ))}
          </div> */}
          {/* <div className="mt-10">
      {links.map((item) => (
        <div key={item.title}>
          <p className="text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase">
            {item.title}
          </p>
          {item.links.map((link) => (
            <div key={link.name}>
              {!link.children ? (
                <NavLink
                  to={`/${link.name}`}
                  onClick={handleCloseSideBar}
                  style={({ isActive }) => ({
                    backgroundColor: isActive ? currentColor : '',
                  })}
                  className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                  }
                >
                  {link.icon}
                  <span className="capitalize">{link.name}</span>
                </NavLink>
              ) : (
                <div className="mb-3">
                  <Dropdown
                    value={dropdownValue}
                    options={link.children.map((child) => ({
                      label: child.name,
                      value: `/${link.name}/${child.name}`,
                    }))}
                    onChange={handleDropdownChange}
                    placeholder={link.name}
                    className="w-full"
                    appendTo="self"
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
    </div> */}
     <div className="mt-10">
      {getlinks(currentUser?.role,currentUser?.status).map((item) => (
        <div key={item.title}>
          <p className="text-gray-400 dark:text-gray-400 m-2 mt-4 uppercase">
            {item.title}
          </p>
          {item.links.map((link) => (
            <div key={link.name}>
              {!link.children ? (
                <NavLink
                  to={`/${link.name}`}
                  onClick={handleCloseSideBar}
                  style={({ isActive }) => ({
                    backgroundColor: isActive ? currentColor : '',
                  })}
                  className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                  }
                >
                  {link.icon}
                  <span className="capitalize ">{link.name}</span>
                </NavLink>
              ) : (
                <div>
                  <div
                    onClick={() => handleDropdownClick(link.name)}
                    className="cursor-pointer gap-5 pl-5 pt-3 pb-2.5"
                    // style={{marginLeft:'12%'}}
                  >
                    {link.icon}
                    <span className="capitalize underline ml-5">{link.name}</span>
                  </div>
                  {openDropdown === link.name && (
                    <div className="ml-4">
                      {link.children.map((child) => (
                        <NavLink
                          key={child.name}
                          to={`/${child.name}`}
                          onClick={handleCloseSideBar}
                          style={({ isActive }) => ({
                            backgroundColor: isActive ? currentColor : '',
                          })}
                          className={({ isActive }) =>
                            isActive ? activeLink : normalLink
                          }
                        >
                          {child.icon}
                          <span className="capitalize">{cleanString(child.title)}</span>
                        </NavLink>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
