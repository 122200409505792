import React, { useState, useEffect } from "react";
import Company from "../../../../components/Datatable";
// import { ProductService } from "../../../../utils/data";
import '../../../main.css';
import '../../../flags.css';
import Loader from "../../../../components/Loader";
import apiCall from "../../../../api/Api";

const List = () => {
  const [products, setProducts] = useState(null); 
  const [loader, setloader] = useState(false); 
  const [page, setPage] = useState(1); 
  const [perPage, setPerPage] = useState(10); 
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    const getWalletTransactions = async () => {
      try {
        setloader(true)
        const response = await apiCall("GET", `wallet-transactions?page=${page}&perPage=${perPage}`);
        setTotalRecords(response.meta?.total); // Assuming your API returns total record count
        setProducts(response?.data)
        setloader(false)
      } catch (error) {
        console.error("Failed ", error);
      }
    }
    getWalletTransactions()
    // ProductService.getProducts().then((data) => setProducts(data));
  }, [page, perPage]);
  
  const column = [
    { 
      name:'id',
      title:'ID',
      sortable:true
    },
    { 
      name:'description',
      title:'Detail',
      sortable:true
    },
    { 
      name:'payment_source',
      title:'Payment Source',
      sortable:true
    },
    // { 
    //   name:'job.service_name',
    //   title:'Job Title',
    //   sortable:true
    // },
    { 
      name:'job.description',
      title:'Job Detail',
      sortable:true
    },
    { 
      name:'user.user.first_name',
      title:'Customer Name',
      sortable:true
    },
    { 
      name:'debit',
      title:'Debit',
      sortable:true
    },
    { 
      name:'credit',
      title:'Credit',
      sortable:true
    },
 
    { 
      name:'currency',
      title:'Currency',
      sortable:true
    },

  ]; 

  // Handle page change
  const handlePageChange = (newPage, newRowsPerPage) => {
    setPage(newPage);
    setPerPage(newRowsPerPage);
   };

      // Loader here
  if (loader === true) return <Loader/>;
  return (
    <div>
      <Company 
      title='All Wallet Transactions' 
      data={products} 
      column={column} 
      detailRoute='transactions'
      totalRecords={totalRecords}
      perPage={perPage}
      page={page}
      onPageChange={handlePageChange}
      loading={loader}
      />
    </div>
  );
};

export default List;
