import React, { useState, useEffect } from "react";
import Company from "../../../../components/Datatable";
// import { ProductService } from "../../../../utils/data";
import '../../../main.css';
import '../../../flags.css';
import Loader from "../../../../components/Loader";
import apiCall from "../../../../api/Api";
import { useLocation, useParams } from "react-router-dom";

const List = () => {
  const [products, setProducts] = useState(null); 
  const location = useLocation()
  const {id} =  useParams()
  const title  = location?.state && location?.state.title;

  useEffect(() => {
    const getWalletTransactions = async () => {
      try {
        const response = await apiCall("GET", `wallet-with-transactions/${id}`);
        console.log(response, 'wallet-with-transactions response');
        setProducts(response)
      } catch (error) {
        console.error("Failed ", error);
      }
    }
    getWalletTransactions()
    // ProductService.getProducts().then((data) => setProducts(data));
  }, [id]);
  
  const column = [
    { 
      name:'id',
      title:'ID'
    },
    { 
      name:'description',
      title:'Detail'
    },
    { 
      name:'payment_source',
      title:'Payment Source'
    },
    { 
      name:'job.service_name',
      title:'Job Title'
    },
    { 
      name:'job.description',
      title:'Job Detail'
    },
    { 
      name:'user.user.first_name',
      title:'Customer Name'
    },
    { 
      name:'debit',
      title:'Debit'
    },
    { 
      name:'credit',
      title:'Credit'
    },
 
    { 
      name:'currency',
      title:'Currency'
    },

  ]; 


      // Loader here
  if (products === null) return <Loader/>;
  return (
    <div>
      <Company title={title ? `${title} Wallets Transactions` : 'Wallet Transactions'} data={products} column={column} detailRoute='wallet-transaction'/>
    </div>
  );
};

export default List;
