import React, { useState, useEffect } from "react";
import Company from "../../../../components/Datatable";
import { ProductService } from "../../../../utils/data";
import '../../../main.css';
import '../../../flags.css';
import { useParams } from "react-router-dom";
import Loader from '../../../../components/Loader'

const GeekSkills = () => {
    const {id} = useParams()

  const [products, setProducts] = useState(null);
  const [GeekName] = useState(null);
  useEffect(() => {
    const getGeeks = async () => {
      try {
        ProductService.getProducts().then((data) => setProducts(data));
        // const response = await apiCall("GET", "geeks");
        // console.log(response,'response')
        // const result = response.find(item => item.id === parseInt(id));
        // console.log(result,'result')
        // setGeekName(result?.first_name)
        // setProducts(result?.geek_skills)
      } catch (error) {
        console.error("Failed to Add:", error);
      }
    }
    getGeeks()
    
  }, [id]);
  
  // columns here from the API
  const column = [
    { 
      name:'id',
      title:'ID'
    },
    { 
      name:'code',
      title:'Skill ID'
    },
    { 
      name:'name',
      title:'Skill Name'
    },
    { 
      name:'rating',
      title:'Self Score'
    },
    { 
      name:'rating',
      title:'System Score'
    },
  
  ]; 

      // Loader here
  if (products === null) return <Loader/>;
  return (
    <div>
      <Company title={GeekName ? `${GeekName} Skills` : 'Geek Skills'} data={products} column={column} showFurtherDetailButton={false} />
    </div>
  );
};

export default GeekSkills;
