// DetailPage.js

import React from 'react';
import { useParams,useLocation } from 'react-router-dom';
import { DetailPage } from '../../../../components';

const Detail = () => {
  const { id } = useParams();
  // const params = useParams();
  const location = useLocation();
  const  endpoint  = location?.state?.endpoint;
  console.log(endpoint,'ss')

  return (
  <DetailPage
    apiUrl={`${endpoint?.apiUrl}/${id}`}
    titleKey={endpoint}
    subtitleKey="last_name"
    imageKey="imageUrl"
    ignoreKeys={['created_at', 'updated_at','addressable_type','addressable_id']} // Fields to ignore
    // actions={actions}
/>
  );
};

export default Detail;
